const updatepoitemFormatter = (data, defaultFormData) => {
  const dataSet = data?.data || data;
  const formData = JSON.parse(JSON.stringify(defaultFormData));
  formData["product_variant"]?.forEach((element) => {
    element["purchase_order_id"] = dataSet["id"];
    element["poitem_status"] = "OPEN";
  });
  return formData;
};

export default updatepoitemFormatter;
