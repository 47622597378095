// @ts-nocheck

import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormWidget, Image, PMButton, PMCard, PMGrid } from "../lib";
import { setStore } from "../store/appSlice";

import { errorSerializer } from "../helperFn/errorSerializer";
import { useLoginApiMutation } from "../store/loginApiSlice";

function Login() {
    const loginApiMutation = useLoginApiMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const fpredirect = (data) => {
        history("/forgot-password/");
    };

    const forgotPassword = (data) => {
        fpredirect(data);
    };

    const afterLogin = (data) => {
        history("/");
    };
    
    const storeLoginData = (data) => {
        var formatedData = {
            authToken: data?.data["access_token"],
            userId: data?.data["user_id"],
            username: data?.data["user"],
            email: data?.data["email"],
            phone: data?.data["phone"],
        };
        dispatch(setStore(formatedData));
    };

    const loginApi = async (data) => {
        var apiData = data;

        if (apiData) {
            await loginApiMutation[0]({ username: apiData["username"], password: apiData["password"] }).unwrap();
        }
    };

    const loginFormSucess = (data) => {
        storeLoginData(data);
        afterLogin(data);
    };
    useEffect(() => {
        if (loginApiMutation[1].isSuccess && (!loginApiMutation[1]?.data?.status_code || loginApiMutation[1]?.data?.status_code == 200)) {

            loginFormSucess(loginApiMutation[1]);
            
        }
        if (loginApiMutation[1].isError || (loginApiMutation[1]?.data?.status_code && loginApiMutation[1]?.data?.status_code != 200) ) {

            enqueueSnackbar(errorSerializer((loginApiMutation[1]?.data||loginApiMutation[1])), { variant: "error" });
            
        }
    }, [loginApiMutation]);

    const loginForm = (data) => {
        loginApi(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid
                container={true}
                background="#007493"
                sx={{ direction: "column", alignItems: "center", justifyContent: "center" }}
                item={true}
                minHeight="100vh"
            >
                <PMGrid item={true} container={true} md={3} direction="column" background="#FFFFFF">
                    <PMCard raised={true}>
                        <PMGrid container={true} rowSpacing={3} alignSelf="center">
                            <PMGrid
                                item={true}
                                xs={12}
                                container={true}
                                sx={{ "margin-top": 10 }}
                                justifyContent="center"
                            >
                                <Image
                                    image="https://www.currytreepastes.com/image/catalog/logo-main.png"
                                    md={6}
                                ></Image>
                            </PMGrid>

                            <PMGrid item={true} xs={12}>
                                <FormWidget
                                    direction="column"
                                    header="Enter Email/Username and Password to login"
                                    Onsubmit={loginForm}
                                    showCancelButton={false}
                                    fieldsets={[
                                        {
                                            direction: "column",
                                            fields: [
                                                {
                                                    label: "Email/Username",
                                                    name: "username",
                                                    type: "text",
                                                    required: true,
                                                    fullWidth: true,
                                                },
                                                {
                                                    label: "Password",
                                                    name: "password",
                                                    type: "password",
                                                    required: true,
                                                    fullWidth: true,
                                                },
                                            ],
                                        },
                                    ]}
                                ></FormWidget>

                                <PMGrid container={true} sx={{ "margin-top": 10 }} justifyContent="flex-end" xs={12}>
                                    <PMButton
                                        label="forgot password ?"
                                        sx={{ "align-self": "right" }}
                                        onClick={forgotPassword}
                                    ></PMButton>
                                </PMGrid>
                            </PMGrid>
                        </PMGrid>
                    </PMCard>
                </PMGrid>
            </PMGrid>
        </div>
    );
}
export default Login;
