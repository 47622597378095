// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, PMCard, Image, FormWidget, PMIcon, PMText } from "../lib";

import { useForgotPasswordMutation } from "../store/forgotPasswordSlice";
import { default as forgotPasswordFormatter } from "../transformations/forgotPasswordFormatter";
function ForgotPassword() {
    const forgotPasswordMutation = useForgotPasswordMutation();
    const [submited, setsubmited] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Email Sent", { variant: "success" });
    };

    const formSubmited = () => {
        setsubmited(!submited);
    };

    const forgotPassword = async (data) => {
        var apiData = forgotPasswordFormatter(data);

        if (apiData) {
            await forgotPasswordMutation[0]({ email: apiData["useremail"], domain: apiData["domain"] }).unwrap();
        }
    };

    const loginFormSucess = (data) => {
        formSubmited(data);
        showSuccessAlert(data);
    };
    useEffect(() => {
        if (forgotPasswordMutation[1].isSuccess) {
            loginFormSucess(forgotPasswordMutation[1]);
        }
        if (forgotPasswordMutation[1].isError) {
            enqueueSnackbar(errorSerializer(forgotPasswordMutation[1]), { variant: "error" });
        }
    }, [forgotPasswordMutation]);

    const loginForm = (data) => {
        forgotPassword(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid
                container={true}
                background="#007493"
                sx={{ direction: "column", alignItems: "center", justifyContent: "center" }}
                item={true}
                minHeight="100vh"
            >
                <PMGrid item={true} container={true} md={3} direction="column" background="#FFFFFF">
                    <PMCard raised={true}>
                        <PMGrid container={true} rowSpacing={3} alignSelf="center">
                            <PMGrid
                                item={true}
                                xs={12}
                                container={true}
                                sx={{ "margin-top": 10 }}
                                justifyContent="center"
                            >
                                <Image
                                    image="https://www.currytreepastes.com/image/catalog/logo-main.png"
                                    md={6}
                                ></Image>
                            </PMGrid>

                            <PMGrid item={true} xs={12} justifyContent="center">
                                {!submited ? (
                                    <FormWidget
                                        direction="column"
                                        header="Enter Email to reset Password"
                                        Onsubmit={loginForm}
                                        fieldsets={[
                                            {
                                                direction: "column",
                                                fields: [
                                                    {
                                                        label: "Email",
                                                        name: "useremail",
                                                        type: "email",
                                                        required: true,
                                                        fullWidth: true,
                                                    },
                                                ],
                                            },
                                        ]}
                                    ></FormWidget>
                                ) : (
                                    ""
                                )}
                                {submited ? (
                                    <PMCard>
                                        <PMGrid
                                            item={true}
                                            xs={12}
                                            container={true}
                                            sx={{ "margin-left": 0, p: 2 }}
                                            justifyContent="center"
                                        >
                                            <PMIcon icon="task_alt" color="success" fontSize="large"></PMIcon>

                                            <PMText children="Please check your email for reset password link"></PMText>
                                        </PMGrid>
                                    </PMCard>
                                ) : (
                                    ""
                                )}
                            </PMGrid>
                        </PMGrid>
                    </PMCard>
                </PMGrid>
            </PMGrid>
        </div>
    );
}
export default ForgotPassword;
