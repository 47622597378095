// @ts-nocheck

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { errorSerializer } from "../helperFn/errorSerializer";
import { DetailTableWidget, DetailWidget, FormWidget, PMGrid, TableWidget } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { useCustomerAddressListQuery } from "../store/customerAddressListSlice";

import { useOrderDetailsQuery } from "../store/orderDetailsSlice";

import { useProductVariantListQuery } from "../store/productVariantListSlice";

import { jsonToFormData } from "../helperFn/formData";
import { useNotesCreateMutation } from "../store/notesCreateSlice";
import { useOrderUpdateMutation } from "../store/orderUpdateSlice";
import { default as notesCreateFormatter } from "../transformations/notesCreateFormatter";

import { useShipmentUpdateMutation } from "../store/shipmentUpdateSlice";

import { usePaymentUpdateMutation } from "../store/paymentUpdateSlice";
import { default as orderAddressListFormatter } from "../transformations/orderAddressListFormatter";
import { default as orderUpdateFormatter } from "../transformations/orderUpdateFormatter";
import { default as productVariantListFormatter } from "../transformations/productVariantListFormatter";
function OrderStatusUpdate() {
    const [customerAddressListSkip, setcustomerAddressListSkip] = useState(true);
    const [customerAddressListParams, setcustomerAddressListParams] = useState({});
    const customerAddressListQuery = useCustomerAddressListQuery(customerAddressListParams, {
        skip: customerAddressListSkip,
    });
    const [orderDetailsSkip, setorderDetailsSkip] = useState(true);
    const [unfilteredProducts, setunfilteredProducts] = useState([]);
    const [apiRes, setApiRes] = useState(false);
    const [orderDetailsParams, setorderDetailsParams] = useState({});
    const orderDetailsQuery = useOrderDetailsQuery(orderDetailsParams, { skip: orderDetailsSkip });
    const [productVariantListSkip, setproductVariantListSkip] = useState(true);
    const [productVariantListParams, setproductVariantListParams] = useState({});
    const productVariantListQuery = useProductVariantListQuery(productVariantListParams, {
        skip: productVariantListSkip,
    });
    const notesCreateMutation = useNotesCreateMutation();
    const orderUpdateMutation = useOrderUpdateMutation();
    const shipmentUpdateMutation = useShipmentUpdateMutation();
    const paymentUpdateMutation = usePaymentUpdateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [formDataState, setformDataState] = useState([]);
    const [reviewFormChangeData, setreviewFormChangeData] = useState([]);
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const orderAddressListData = useSelector((states) => states?.appStore?.orderAddressListData);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const reviewFormUpdate = (data) => {
        const responseData = data?.data || data;
        setreviewFormChangeData(responseData);
    };

    const watchReviewFormChange = (data) => {
        reviewFormUpdate(data);
    };

    const pocreated = (data) => {
        history("/orders/");
    };

    const paymentUpdated = (data) => {
        var formatedData = {
            paymentUpdatedData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const paymentUpdate = async (data) => {
        var apiData = data;

        if (apiData) {
            await paymentUpdateMutation[0]({
                id: apiData["payment_id"],
                data: {
                    id: apiData["payment_id"],
                    payment_mode: apiData["payment_mode"],
                    payment_date: apiData["payment_date"],
                },
            }).unwrap();
        }
    };

    const paymentUpdateSuccess = (data) => {
        paymentUpdated(data);
        pocreated(data);
    };
    useEffect(() => {
        if (paymentUpdateMutation[1].isSuccess) {
            paymentUpdateSuccess(paymentUpdateMutation[1]);
        }
        if (paymentUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(paymentUpdateMutation[1]), { variant: "error" });
        }
    }, [paymentUpdateMutation]);

    const shipmentUpdated = (data) => {
        var formatedData = {
            shipmentUpdatedData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const shipmentUpdate = async (data) => {
        var apiData = data;

        if (apiData) {
            await shipmentUpdateMutation[0]({
                id: apiData["shipping_id"],
                data: {
                    id: apiData["shipping_id"],
                    shipping_method: apiData["shipping_method"],
                    tracking_id: apiData["tracking_id"],
                },
            }).unwrap();
        }
    };

    const shipmentUpdateSuccess = (data) => {
        shipmentUpdated(data);
    };
    useEffect(() => {
        if (shipmentUpdateMutation[1].isSuccess) {
            shipmentUpdateSuccess(shipmentUpdateMutation[1]);
        }
        if (shipmentUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(shipmentUpdateMutation[1]), { variant: "error" });
        }
    }, [shipmentUpdateMutation]);

    const notesCreated = (data) => {
        var formatedData = {
            notesCreatedData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const notesCreate = async (data) => {
        var apiData = notesCreateFormatter(data, reviewFormChangeData);

        if (apiData && apiData["note"]) {
            await notesCreateMutation[0]({ order_id: apiData["id"], note: apiData["note"] }).unwrap();
        }
    };

    const notesCreateSuccess = (data) => {
        notesCreated(data);
        setApiRes(false)
    };
    useEffect(() => {
        if (notesCreateMutation[1].isSuccess) {
            notesCreateSuccess(notesCreateMutation[1]);
        }
        if (notesCreateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(notesCreateMutation[1]), { variant: "error" });
        }
    }, [notesCreateMutation]);

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Order Updated", { variant: "success" });
    };

    const orderUpdate = async (data) => {
        var apiData = data;

        if (apiData) {
            await orderUpdateMutation[0]({
                id: apiData["id"],
                data: jsonToFormData({
                    id: apiData["id"],
                    order_status: apiData["order_status"],
                    invoice_file: apiData["invoice_file"],
                    invoice_number: apiData["invoice_number"],
                }),
            }).unwrap();
        }
    };

    const orderCreateSuccess = (data) => {
        showSuccessAlert(data);
        notesCreate(data);
    };
    useEffect(() => {
        if (orderUpdateMutation[1].isSuccess) {
            orderCreateSuccess(orderUpdateMutation[1]);
        }
        if (orderUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(orderUpdateMutation[1]), { variant: "error" });
        }
    }, [orderUpdateMutation]);

    const createOrderApi = (data) => {
        setApiRes(true)
        orderUpdate(data);

        shipmentUpdate(data);

        paymentUpdate(data);
    };

    const customerAddressListStore = (data) => {
        var formatedData = {
            ...orderAddressListFormatter(data?.data || data, formDataState, "orderAddressListData"),
        };
        dispatch(setStore(formatedData));
    };

    const manufacturerSuccess3 = (data) => {
        customerAddressListStore(data);
    };
    useEffect(() => {
        if (customerAddressListQuery.isSuccess) {
            manufacturerSuccess3(customerAddressListQuery);
        } else if (customerAddressListQuery.isError) {
            manufacturerSuccess3(null);
        }
    }, [customerAddressListQuery]);
    const customerAddressList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcustomerAddressListParams({ customer_id: data ? data["customer_id"] : "customer_id" });
        setcustomerAddressListSkip(false);
    };

    const orderDetailsData = (data) => {
        const responseData = data?.data || data;
        setformDataState(orderUpdateFormatter(responseData, unfilteredProducts));
    };

    const orderDetailsSuccess = (data) => {
        orderDetailsData(data);
        customerAddressList(data);
    };
    useEffect(() => {
        if (orderDetailsQuery.isSuccess) {
            orderDetailsSuccess(orderDetailsQuery);
        } else if (orderDetailsQuery.isError) {
            orderDetailsSuccess(null);
        }
    }, [orderDetailsQuery]);
    const orderDetails = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setorderDetailsParams({ id: id });
        setorderDetailsSkip(false);
    };

    const saveProductVariantListData = (data) => {
        setunfilteredProducts(data?.data||data)
        var formatedData = {
            ...productVariantListFormatter(data?.data || data, "productVariantListData"),
        };
        dispatch(setStore(formatedData));
    };

    const productVariantListSuccess = (data) => {
        saveProductVariantListData(data);
        orderDetails(data);
    };
    useEffect(() => {
        if (productVariantListQuery.isSuccess) {
            productVariantListSuccess(productVariantListQuery);
        } else if (productVariantListQuery.isError) {
            productVariantListSuccess(null);
        }
    }, [productVariantListQuery]);
    const productVariantList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductVariantListSkip(false);
    };

    useEffect(() => {
        productVariantList();
    }, [id]);
    var orderStatusMapper = {
        "OPEN":[
            { id: "OPEN", label: "Open" },
            { id: "PENDING", label: "Pending" },
            { id: "PROCESSING", label: "Processing" },
            { id: "PROCESSED", label: "Processed" },
            { id: "SHIPPED", label: "Shipped" },
            { id: "COMPLETE", label: "Complete" },
            { id: "CANCELLED", label: "Cancelled" },
            { id: "RETURNED", label: "Returned" },
        ],
        "PROCESSING":[
            { id: "PROCESSING", label: "Processing" },
            { id: "PROCESSED", label: "Processed" },
            { id: "SHIPPED", label: "Shipped" },
            { id: "COMPLETE", label: "Complete" },
            { id: "CANCELLED", label: "Cancelled" },
            { id: "RETURNED", label: "Returned" },
        ],
        "PENDING":[
            { id: "PENDING", label: "Pending" },
            { id: "PROCESSING", label: "Processing" },
            { id: "PROCESSED", label: "Processed" },
            { id: "SHIPPED", label: "Shipped" },
            { id: "COMPLETE", label: "Complete" },
            { id: "CANCELLED", label: "Cancelled" },
            { id: "RETURNED", label: "Returned" },
        ],
        "PROCESSED":[
            { id: "PROCESSED", label: "Processed" },
            { id: "SHIPPED", label: "Shipped" },
            { id: "COMPLETE", label: "Complete" },
            { id: "CANCELLED", label: "Cancelled" },
            { id: "RETURNED", label: "Returned" },
        ],
        "SHIPPED":[
            { id: "SHIPPED", label: "Shipped" },
            { id: "COMPLETE", label: "Complete" },
            { id: "CANCELLED", label: "Cancelled" },
            { id: "RETURNED", label: "Returned" },
        ],
        "COMPLETE":[
            { id: "COMPLETE", label: "Complete" },
            { id: "CANCELLED", label: "Cancelled" },
            { id: "RETURNED", label: "Returned" },
        ],
        "CANCELLED":[
            { id: "CANCELLED", label: "Cancelled" },
        ],
        "RETURNED":[
            { id: "RETURNED", label: "Returned" },
        ],
    }
    var orderStatus = orderStatusMapper[formDataState.order_status]
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{title:"Edit Order"}}>
                <PMGrid container={true} xs={12}>
                    <PMGrid container={true} xs={6}>
                        <DetailWidget
                            direction="row"
                            xs={12}
                            justifyContent="center"
                            fields={[
                                { label: "Order ID", value: formDataState?.order_id },
                                { label: "Invoice No", value: formDataState?.invoice_number },
                                { label: "Created At", type: "datetime", value: formDataState?.created_at },
                            ]}
                        ></DetailWidget>

                        <DetailWidget
                            direction="row"
                            xs={12}
                            justifyContent="center"
                            fields={[
                                { label: "Customer Name", value: formDataState?.customer?.name },
                                { label: "Email", value: formDataState?.order_email?.email_address },
                                { label: "Phone", value: formDataState?.order_phone?.phone_number },
                            ]}
                        ></DetailWidget>

                        <DetailWidget
                            direction="row"
                            xs={12}
                            justifyContent="center"
                            fields={[
                                { label: "Payment Address", value: orderAddressListData?.payment_address?.address },
                                { label: "Payment City", value: orderAddressListData?.payment_address?.city },
                                { label: "Payment State", value: orderAddressListData?.payment_address?.state },
                                { label: "Payment Pincode", value: orderAddressListData?.payment_address?.pincode },
                            ]}
                        ></DetailWidget>

                        <DetailWidget
                            direction="row"
                            justifyContent="center"
                            xs={12}
                            fields={[
                                { label: "Shipment Address", value: orderAddressListData?.shipment_address?.address },
                                { label: "Shipment City", value: orderAddressListData?.shipment_address?.city },
                                { label: "Shipment State", value: orderAddressListData?.shipment_address?.state },
                                { label: "Shipment Pincode", value: orderAddressListData?.shipment_address?.pincode },
                            ]}
                        ></DetailWidget>
                    </PMGrid>

                    <PMGrid container={true} sx={{ alignContent: "baseline" }} xs={6}>
                        <DetailTableWidget
                            showSerialNumber={false}
                            showQuickFilter={false}
                            hideFooter={true}
                            xs={12}
                            rows={formDataState?.orderitem || []}
                            details={[{key:"Net Amount","value":("₹" + (
                                formDataState?.total_amount -
                                (formDataState.total_amount -
                                    formDataState.total_amount * (100 / (100 + 12)))
                            ).toFixed(2)),division:true},{key:"GST",value:("₹" + (
                                formDataState?.total_amount -
                                formDataState?.total_amount * (100 / (100 + 12))
                            ).toFixed(2))},{key:"Total Amount",value:"₹" +formDataState?.total_amount?.toFixed(2),division:true}]||[]}
                            columns={[
                                { field: "product_name", headerName: "Product" },
                                // { field: "order_item_status", headerName: "Item Status" },
                                { field: "quantity", headerName: "Quantity" },
                                { field: "per_item_price", headerName: "Price", type: "currency" },
                                { field: "price", headerName: "Total", type: "currency" },
                            ]}
                        ></DetailTableWidget>
                        
                        {formDataState?.ordernotes?.length ? (
                            <TableWidget
                                showSerialNumber={false}
                                header="Previous Order Notes"
                                showQuickFilter={false}
                                hideFooter={true}
                                xs={12}
                                rows={formDataState?.ordernotes || []}
                                columns={[
                                    { field: "created_at", headerName: "Added On", type: "datetime" },
                                    { field: "note", headerName: "Note" },
                                ]}
                            ></TableWidget>
                        ) : (
                            ""
                        )}
                    </PMGrid>
                    {formDataState.channel==="WEBSITE"?( <DetailWidget
                            direction="row"
                            justifyContent="center"
                            xs={6}
                            fields={[
                                { label: "Shipment Method", value: formDataState?.shipping_method },
                                { label: "Shipment Tracking ID", value: formDataState?.tracking_id },
                                { label: "Payment Date", value: formDataState?.payment_date, type: "datetime" },
                                { label: "Payment Method", value: formDataState?.payment_mode },
                                { label: "Order Status", value: formDataState?.order_status },
                                { label: "Invoice File", value: formDataState?.invoice_file },
                            ]}
                        ></DetailWidget>):(<FormWidget
                            direction="column"
                            submitButtonLabel="Update Order"
                            header="Order Status"
                            defaultValues={formDataState}
                            Onsubmit={createOrderApi}
                            isLoading={apiRes}
                            Onchange={watchReviewFormChange}
                            cancelRedirect="/orders"
                            fieldsets={[
                                {
                                    direction: "row",
                                    fields: [
                                        { label: "orderid", name: "id", type: "hidden" },
                                        { label: "shipping_id", name: "shipping_id", type: "hidden" },
                                        { label: "payment_id", name: "payment_id", type: "hidden" },
                                        {
                                            label: "Shipping Method",
                                            md: "4",
                                            name: "shipping_method",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "FREE_SHIPPING", label: "Free Shipping" },
                                                { id: "FLAT_30_RS_SHIPMENT", label: "Flat 30 Rs Shipment" },
                                            ],
                                        },
                                        { label: "Shipment Tracking ID", md: "4", name: "tracking_id", type: "text" },
                                        { label: "Payment Date", md: "4", name: "payment_date", type: "date" },
                                        {
                                            label: "Payment Method",
                                            md: "4",
                                            name: "payment_mode",
                                            type: "select",
                                            required: true,
                                            options: [
                                                { id: "RAZORPAY", label: "Razorpay" },
                                                { id: "PAYTM", label: "Paytm" },
                                                { id: "CREDIT_PERIOD", label: "Creditperiod" },
                                                { id: "FREEORDER", label: "Freeorder" },
                                                { id: "CASH", label: "Cash" },
                                                { id: "CREDIT_CARD", label: "Creditcard" },
                                                { id: "DEBIT_CARD", label: "Debitcard" },
                                                { id: "NET_BANKING", label: "Netbanking" },
                                            ],
                                        },
                                        {
                                            label: "Order Status",
                                            md: "4",
                                            name: "order_status",
                                            required: true,
                                            type: "select",
                                            options: orderStatus,
                                        },
                                        {
                                            label: "Upload Order Invoice",
                                            md: "4",
                                            name: "invoice_file",
                                            type: "file",
                                            accept: "image/*, application/pdf",
                                        },
                                        { label: "Add Notes", md: "4", name: "note", type: "text" },
                                    ],
                                },
                            ]}
                        ></FormWidget>)}
                    
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default OrderStatusUpdate;
