const stocklocationListFormatter = (data, dataKey) => {
  const dataSet = {};
  dataSet[dataKey] = { delivery_warehouse_id: "" };
  if (data?.items[0] && data?.items[0].id) {
    dataSet[dataKey]["delivery_warehouse_id"] = JSON.parse(
      JSON.stringify(data.items[0].id)
    );
    dataSet[dataKey]["po_status"] = "OPEN";
    dataSet[dataKey]["po_date"] = new Date();
  }
  return dataSet;
};

export default stocklocationListFormatter;
