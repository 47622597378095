const productFormSubmitFormatter = (data) => {
  if (checkIfValidUUID(data?.id)) {
    return { order_item_status: "CANCELLED", id: data.id };
  }
  return {};
};

const checkIfValidUUID = (str) => {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
};

export default productFormSubmitFormatter;
