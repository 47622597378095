// @ts-nocheck

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorSerializer } from "../helperFn/errorSerializer";
import { FormWidget, PMGrid } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { useManufacturerQuery } from "../store/manufacturerSlice";

import { useProductVariantListQuery } from "../store/productVariantListSlice";

import { useStocklocationListQuery } from "../store/stocklocationListSlice";

import { usePoPdfCreateQuery } from "../store/poPdfCreateSlice";

import { jsonToFormData } from "../helperFn/formData";
import { useCreatepoitemMutation } from "../store/createpoitemSlice";
import { useCreatepoMutation } from "../store/createpoSlice";
import { default as apiRequestFormatter } from "../transformations/apiRequestFormatter";

import { useManufacturerDetailsQuery } from "../store/manufacturerDetailsSlice";
import { default as formDataFormatter } from "../transformations/formDataFormatter";
import { default as manufacturerDropDown } from "../transformations/manufacturerDropDown";
import { default as productVariantListFormatter } from "../transformations/productVariantListFormatter";
import { default as stocklocationListFormatter } from "../transformations/stocklocationListFormatter";
function ShipmentPo() {
    const defaultFormData = useSelector((states) => states?.appStore?.defaultFormData);
    const [manufacturerSkip, setmanufacturerSkip] = useState(true);
    const [manufacturerParams, setmanufacturerParams] = useState({});
    const manufacturerQuery = useManufacturerQuery(manufacturerParams, { skip: manufacturerSkip });
    const [productVariantListSkip, setproductVariantListSkip] = useState(true);
    const [productVariantListParams, setproductVariantListParams] = useState({});
    const productVariantListQuery = useProductVariantListQuery(productVariantListParams, {
        skip: productVariantListSkip,
    });
    const [stocklocationListSkip, setstocklocationListSkip] = useState(true);
    const [stocklocationListParams, setstocklocationListParams] = useState({});
    const [apiRes, setApiRes] = useState(false);
    const stocklocationListQuery = useStocklocationListQuery(stocklocationListParams, { skip: stocklocationListSkip });
    const [poPdfCreateSkip, setpoPdfCreateSkip] = useState(true);
    const [poPdfCreateParams, setpoPdfCreateParams] = useState({});
    const poPdfCreateQuery = usePoPdfCreateQuery(poPdfCreateParams, { skip: poPdfCreateSkip });
    const createpoitemMutation = useCreatepoitemMutation();
    const createpoMutation = useCreatepoMutation();
    const [manufacturerDetailsSkip, setmanufacturerDetailsSkip] = useState(true);
    const [manufacturerDetailsParams, setmanufacturerDetailsParams] = useState({});
    const manufacturerDetailsQuery = useManufacturerDetailsQuery(manufacturerDetailsParams, {
        skip: manufacturerDetailsSkip,
    });
    const history = useNavigate();
    const dispatch = useDispatch();
    const [formDataState, setformDataState] = useState([]);
    const manufacturerData = useSelector((states) => states?.appStore?.manufacturerData);
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const { enqueueSnackbar } = useSnackbar();

    const formdataUpadte = (data) => {
        const responseData = data?.data || data;
        setformDataState(responseData);
    };

    const manufacturerDetailsStore = (data) => {
        var formatedData = {
            ...formDataFormatter(data?.data || data, "defaultFormData"),
        };
        dispatch(setStore(formatedData));
    };

    const manufacturerSuccess3 = (data) => {
        manufacturerDetailsStore(data);
    };
    useEffect(() => {
        if (manufacturerDetailsQuery.isSuccess) {
            manufacturerSuccess3(manufacturerDetailsQuery);
        } else if (manufacturerDetailsQuery.isError) {
            manufacturerSuccess3(null);
        }
    }, [manufacturerDetailsQuery]);
    const manufacturerDetails = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        if (data && data["supplier"]) {
            setmanufacturerDetailsParams({ id: data ? data["supplier"] : "supplier" });
            setmanufacturerDetailsSkip(false);
        }
    };

    const watchFormChange = (data) => {
        manufacturerDetails(data);

        formdataUpadte(data);
    };

    const pocreated = (data) => {
        history("/shipment/polist/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Purchase Order Created", { variant: "success" });
    };

    const pdfCreationSuccess = (data) => {
        showSuccessAlert(data);
        pocreated(data);
    };
    useEffect(() => {
        if (poPdfCreateQuery.isSuccess) {
            pdfCreationSuccess(poPdfCreateQuery);
        } else if (poPdfCreateQuery.isError) {
            pdfCreationSuccess(null);
        }
    }, [poPdfCreateQuery]);
    const poPdfCreate = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpoPdfCreateParams({ id: data ? data["purchase_order_id"] : "purchase_order_id" });
        setpoPdfCreateSkip(false);
    };

    const createpoitem = async (data) => {
        var apiData = apiRequestFormatter(data, formDataState);
        if (apiData["product_variant"] && apiData["product_variant"].length) {
            apiData["product_variant"].forEach(async (apiData) => {
                if (apiData) {
                    await createpoitemMutation[0]({
                        purchase_order_id: apiData["id"],
                        product_variant_id: apiData["product_variant_id"],
                        quantity: apiData["quantity"],
                        rate: apiData["rate"],
                        poitem_status: apiData["poitem_status"],
                    }).unwrap();
                }
            });
        }
    };

    const createpoitemSuccess = (data) => {
        poPdfCreate(data);
        setApiRes(false)
    };
    useEffect(() => {
        if (createpoitemMutation[1].isSuccess) {
            createpoitemSuccess(createpoitemMutation[1]);
        }
        if (createpoitemMutation[1].isError) {
            setApiRes(false)
            enqueueSnackbar(errorSerializer(createpoitemMutation[1]), { variant: "error" });
        }
    }, [createpoitemMutation]);

    const createpo = async (data) => {
        var apiData = data;

        if (apiData) {
            await createpoMutation[0](
                jsonToFormData({
                    po_date: apiData["po_date"],
                    manufacturer_id: apiData["supplier"],
                    delivery_warehouse_id: apiData["delivery_warehouse_id"],
                    po_status: apiData["po_status"],
                })
            ).unwrap();
        }
    };

    const createpoApiSuscess = (data) => {
        createpoitem(data);
    };
    useEffect(() => {
        if (createpoMutation[1].isSuccess) {
            createpoApiSuscess(createpoMutation[1]);
        }
        if (createpoMutation[1].isError) {
            setApiRes(false)
            enqueueSnackbar(errorSerializer(createpoMutation[1]), { variant: "error" });
        }
    }, [createpoMutation]);

    const createpoName = (data) => {
        setApiRes(true)
        createpo(data);
    };

    const savestocklocationListData = (data) => {
        var formatedData = {
            ...stocklocationListFormatter(data?.data || data, "defaultFormData"),
        };
        dispatch(setStore(formatedData));
    };

    const stocklocationListSuccess = (data) => {
        savestocklocationListData(data);
    };
    useEffect(() => {
        if (stocklocationListQuery.isSuccess) {
            stocklocationListSuccess(stocklocationListQuery);
        } else if (stocklocationListQuery.isError) {
            stocklocationListSuccess(null);
        }
    }, [stocklocationListQuery]);
    const stocklocationList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setstocklocationListSkip(false);
    };

    const saveProductVariantListData = (data) => {
        var formatedData = {
            ...productVariantListFormatter(data?.data || data, "productVariantListData"),
        };
        dispatch(setStore(formatedData));
    };

    const productVariantListSuccess = (data) => {
        saveProductVariantListData(data);
    };
    useEffect(() => {
        if (productVariantListQuery.isSuccess) {
            productVariantListSuccess(productVariantListQuery);
        } else if (productVariantListQuery.isError) {
            productVariantListSuccess(null);
        }
    }, [productVariantListQuery]);
    const productVariantList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductVariantListSkip(false);
    };

    const saveManufacturerData = (data) => {
        var formatedData = {
            ...manufacturerDropDown(data?.data || data, "manufacturerData"),
        };
        dispatch(setStore(formatedData));
    };

    const manufacturerSuccess = (data) => {
        saveManufacturerData(data);
    };
    useEffect(() => {
        if (manufacturerQuery.isSuccess) {
            manufacturerSuccess(manufacturerQuery);
        } else if (manufacturerQuery.isError) {
            manufacturerSuccess(null);
        }
    }, [manufacturerQuery]);
    const manufacturer = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setmanufacturerSkip(false);
    };

    useEffect(() => {
        manufacturer();
        productVariantList();
        stocklocationList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget currentUrl="/shipment/purchaseorder/" data={{title:"Create Purchase Order"}}>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        
                        direction="column"
                        header="Create New Purchase Order"
                        defaultValues={defaultFormData || []}
                        Onsubmit={createpoName}
                        isLoading={apiRes}
                        cancelRedirect="/shipment/polist"
                        Onblur={watchFormChange}
                        fieldsets={[
                            {
                                direction: "row",
                                fields: [
                                    { label: "PO Date", name: "po_date", type: "date", required: true },
                                    { label: "delivery_warehouse_id", name: "delivery_warehouse_id", type: "hidden" },
                                    { label: "po_status", name: "po_status", type: "hidden" },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Supplier Details",
                                fields: [
                                    {
                                        label: "Select Supplier",
                                        name: "supplier",
                                        type: "select",
                                        required: true,
                                        options: manufacturerData,
                                    },
                                    { label: "Supplier Address", name: "address", type: "display" },
                                    { label: "Supplier GSTIN", name: "gstin", type: "display" },
                                ],
                            },
                            {
                                direction: "row",
                                group: "product_variant",
                                additiontitle: "+ Add Product",
                                delete: true,
                                count: 1,
                                header: "Product Details",
                                fields: [
                                    {
                                        label: "Select Product",
                                        md: "4",
                                        name: "product_variant_id",
                                        type: "select",
                                        required: true,
                                        options: productVariantListData,
                                    },
                                    {
                                        label: "Enter Quantity Required(EA)",
                                        name: "quantity",
                                        type: "text",
                                        required: true,
                                        validation: { min: 1, pattern: "^\\d+$" },
                                    },
                                    {
                                        label: "Enter Price (INR) (Without GST)",
                                        name: "rate",
                                        type: "text",
                                        required: true,
                                        validation: { min: 1, pattern: "^\\d+(?:\\.\\d+)?$" },
                                        prefix: "₹",
                                    },
                                ],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ShipmentPo;
