const resetPasswordFormatter = (data, token, enqueueSnackbar) => {
  data["domain"] = window.location.origin;
  data["token"] = token;
  if (
    data["password"] &&
    data["repassword"] &&
    data["password"] == data["repassword"]
  ) {
    data["password_check"] = true;
  } else {
    enqueueSnackbar(`Password do not match`, { variant: "error" });
  }
  return data;
};
export default resetPasswordFormatter;
