const orderListFormatter = (data, dataKey) => {
  const dataSet = {};
  dataSet[dataKey] = JSON.parse(JSON.stringify(data));
  if (dataSet[dataKey] && dataSet[dataKey]["items"]) {
    dataSet[dataKey]["items"].forEach((element) => {
      element["customer_name"] = element["customer"]["name"];
      element["editable"] = false;
      if (["OPEN", "PENDING", "PROCESSING", "PROCESSED"].includes(element["order_status"])) {
        element["editable"] = true;
      }
      if(element["channel"]=="WEBSITE"){
        element["editable"] = false;
      }
    });
  }
  return dataSet;
};

export default orderListFormatter;
