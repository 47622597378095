const productVariantListFormatter = (data, dataKey) => {
  const dataSet = {};
  dataSet[dataKey] = data?.items ? JSON.parse(JSON.stringify(data?.items)) : [];
  let items = []
  dataSet[dataKey].forEach((element) => {
    if (element) {
      element["label"] = element["product"]["name"];
      element["product_name"] = element["product"]["name"];
      element["available"] = element["stock"]["available"] || 0;
      element["pipeline"] = element["stock"]["pipeline"] || 0;
      element["locked_by_order"] = element["stock"]["locked_by_order"] || 0;
      element["shipped"] = element["stock"]["shipped"] || 0;
      if (element.status == "Active"){
        items.push(element)
      }
    }
  });
  dataSet[dataKey] = items

  return dataSet;
};

export default productVariantListFormatter;
