import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const poDetailsPatchSlice = createApi({
    reducerPath: "poDetailsPatch",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://brand-logistics.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        poDetailsPatch: builder.mutation({
            query: ({ id, data }) => ({
                url: "/inventory/api/v1/purchaseorder/update?id=" + id,
                method: "PATCH",
                body: data,
            }),
        }),
    }),
});

export const { usePoDetailsPatchMutation } = poDetailsPatchSlice;
