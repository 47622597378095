const qualityCheckListFormatter = (data, dataKey) => {
  const dataSet = {};
  dataSet[dataKey] = JSON.parse(JSON.stringify(data));
  if (dataSet[dataKey] && dataSet[dataKey]["items"]) {
    dataSet[dataKey]["items"].forEach((element) => {
      element["product_name"] =
        element["receiveditem"]["poitem"]["productvariant"]["product"]["name"];
      element["batch_no"] = element["receiveditem"]["batch_no"];
      element["quantity"] = element["receiveditem"]["quantity"];
      element["leakage"] = element["leakage"] || 0;
      element["blooted"] = element["blooted"] || 0;
      element["other"] = element["other"] || 0;
      element["shipment_date"] = element["receiveditem"]["shipment_date"];
      element["supplier_name"] =
        element["receiveditem"]["poitem"]["purchaseorder"]["manufacturer"][
          "name"
        ];
      element["total_inwarded_qantity"] =
        parseInt(element["receiveditem"]["quantity"]) -
        (parseInt(element["leakage"] || 0) +
          parseInt(element["blooted"] || 0) +
          parseInt(element["other"] || 0));
      element["defect_percentage"] = element["receiveditem"]["quantity"]
        ? `${Math.round(
            ((parseInt(element["receiveditem"]["quantity"]) -
              parseInt(element["total_inwarded_qantity"])) *
              100) /
              parseInt(element["receiveditem"]["quantity"])
          )} %`
        : "0 %";

      element["download_item"] = [];
      element["qualitycheckdocument"].forEach((docs) => {
        element["download_item"].push(docs["document"]);
      });
    });
  }

  return dataSet;
};

export default qualityCheckListFormatter;
