const paymentFormFormatter = (data, addressdata) => {
  const dataSet = data;
  if (dataSet["payment_address_id"]) {
    const payment_data = addressdata.filter(
      (ele) => ele.id == dataSet["payment_address_id"]
    )[0];
    if (payment_data) {
      dataSet["placeholder_address"] = payment_data["address"];
      dataSet["placeholder_city"] = payment_data["city"];
      dataSet["placeholder_state"] = payment_data["state"];
      dataSet["placeholder_pincode"] = payment_data["pincode"];
    }
  } else {
    dataSet["placeholder_address"] = null;
    dataSet["placeholder_city"] = null;
    dataSet["placeholder_state"] = null;
    dataSet["placeholder_pincode"] = null;
  }
  return dataSet;
};

export default paymentFormFormatter;
