import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const customerPhoneCreateSlice = createApi({
    reducerPath: "customerPhoneCreate",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://brand-logistics.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        customerPhoneCreate: builder.mutation({
            query: (data) => ({ url: "/order-management/api/v1/phone/create", method: "POST", body: data }),
        }),
    }),
});

export const { useCustomerPhoneCreateMutation } = customerPhoneCreateSlice;
