const recievedItemFormatter = (data, dataKey) => {
  const dataSet = {};
  dataSet[dataKey] = JSON.parse(JSON.stringify(data));
  if (dataSet[dataKey]["poitem"]) {
    dataSet[dataKey]["product_name"] =
      dataSet[dataKey]["poitem"]["productvariant"]["product"]["name"];
    dataSet[dataKey]["received_item_id"] = dataSet[dataKey]["id"];
  }
  return dataSet;
};

export default recievedItemFormatter;
