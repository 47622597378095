// @ts-nocheck

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormWidget, PMGrid, TableWidget } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { useRecievedItemListQuery } from "../store/recievedItemListSlice";
import { default as recievedItemListFormatter } from "../transformations/recievedItemListFormatter";
function ShipmentRecievedList() {
    const [recievedItemListSkip, setrecievedItemListSkip] = useState(true);
    const [recievedItemListParams, setrecievedItemListParams] = useState({});
    const recievedItemListQuery = useRecievedItemListQuery(recievedItemListParams, { skip: recievedItemListSkip });
    const dispatch = useDispatch();
    const recievedItemListData = useSelector((states) => states?.appStore?.recievedItemListData);
    const [filterParams, setfilterParams] = useState({batch_no:"",shipment_date__lte:null,shipment_date__gte:null});
    const [apiRes, setApiRes] = useState(false);

    const recievedItemListSuccess = (data) => {
        saverecievedItemListData(data);
    };
    useEffect(() => {
        if (recievedItemListQuery.isSuccess) {
            recievedItemListSuccess(recievedItemListQuery);
        } else if (recievedItemListQuery.isError) {
            recievedItemListSuccess(null);
        }
    }, [recievedItemListQuery]);
    const recievedItemList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;
        console.log(data, "data")
        if(data){
            setfilterParams(data)
        }
        const  filter = {}
        if (data?.batch_no && data?.batch_no != ""){
            filter["batch_no"] = data["batch_no"]
        }
        if (data?.shipment_date__lte && data?.shipment_date__lte != ""){
            filter["shipment_date__date_lte"] = data["shipment_date__lte"].split(" ")[0]
        }
        if (data?.shipment_date__gte && data?.shipment_date__gte != ""){
            filter["shipment_date__date_gte"] = data["shipment_date__gte"].split(" ")[0]
        }
        console.log(filter, "filter")
        setrecievedItemListParams({ order_by: data ? data["-created_at"] : "-created_at", ...filter });
        setrecievedItemListSkip(false);
    };

    const saverecievedItemListData = (data) => {
        var formatedData = {
            ...recievedItemListFormatter(data?.data || data, "recievedItemListData"),
        };
        dispatch(setStore(formatedData));
    };

    useEffect(() => {
        recievedItemList();
    }, []);

    const recievedItemListReFetch = (tableParams) => {

        const  filter = {}
        if (filterParams.batch_no && filterParams.batch_no != ""){
            filter["batch_no"] = filterParams["batch_no"]
        }
        if (filterParams.shipment_date__lte && filterParams.shipment_date__lte != ""){
            filter["shipment_date__date_lte"] = filterParams["shipment_date__lte"].split(" ")[0]
        }
        if (filterParams.shipment_date__gte && filterParams.shipment_date__gte != ""){
            filter["shipment_date__date_gte"] = filterParams["shipment_date__gte"].split(" ")[0]
        }
        setrecievedItemListParams({ order_by: "-created_at",...filter, ...tableParams });
    };

    const searchOrder = (data) => {
        console.log("searchOrder", data)

        recievedItemList(data)
    };
    const clearFilter = () => {
        console.log("clearFilter", filterParams)
        setfilterParams({batch_no:"",shipment_date__lte:null,shipment_date__gte:null})
        
        recievedItemList()
    };
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{title:"View Shipments"}}>
                <PMGrid container={true} marginTop={2}>
                    
                <FormWidget
                            direction="row"
                            bgColor="inherit"
                            header=""
                            showCancelButton={false}
                            cancelRedirect={"/orders"}
                            showClearButton={true}
                            submitButtonLabel="Apply Filter"
                            Onsubmit={searchOrder}
                            Onclear={clearFilter}
                            defaultValues={filterParams}
                            isLoading={apiRes}
                            
                            fieldsets={[
                                {
                                    direction: "row",
                                fields:[
                                    {
                                    label: "Enter Batch No",
                                    md: "4",
                                    name: "batch_no",
                                    type: "text",
                                    
                                },
                                    {
                                    label: "Start Date",
                                    md: "4",
                                    name: "shipment_date__gte",
                                    type: "date",
                                    
                                },
                                    {
                                    label: "End Date",
                                    md: "4",
                                    name: "shipment_date__lte",
                                    type: "date",
                                },
                            ],}
                            ]}
                        ></FormWidget>
                    <TableWidget
                        header="Received Goods"
                        showSerialNumber={true}
                        showQuickFilter={false}
                        xs={12}
                        tableApi={recievedItemListData || []}
                        updateTable={recievedItemListReFetch}
                        columns={[
                            { field: "shipment_date", headerName: "Delivery Date", flex: 0.8, type: "date" },
                            {
                                field: "po_number",
                                headerName: "PO Number",
                                highlight: true,
                                flex: 1.3,
                                sortable: false,
                            },
                            {
                                field: "product_name",
                                headerName: "Product Name",
                                highlight: true,
                                flex: 1.5,
                                sortable: false,
                            },
                            { field: "batch_no", headerName: "Batch No" },
                            { field: "quantity", headerName: "Qty Received (EA)" },
                            { field: "no_of_mono_carton", headerName: "No Of Cartons" },
                            { field: "received_item_status", headerName: "Status" },
                            { field: "grn_file", headerName: "Download GRN", type: "download", sortable: false },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ShipmentRecievedList;
