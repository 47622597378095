const stockLedgerFormatter = (data, dataKey) => {
    const dataSet = {};
    dataSet[dataKey] = JSON.parse(JSON.stringify(data));
    if(dataSet[dataKey] && dataSet[dataKey]["items"]){
        dataSet[dataKey]["items"].forEach((element) => {
            element["product_name"] = element["productvariant"]["product"]["name"];
        });
    }

    return dataSet;
};

export default stockLedgerFormatter;