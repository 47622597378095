import Moment from "moment";

const recievedItemCreateFromatter = (data, defaultFormData) => {
  const dataSet = data?.data || data;
  dataSet["poitem"]?.forEach((element) => {
    element["shipment_date"] = Moment(dataSet["shipment_date"]).format(
      "yyyy-MM-DDTh:m:sZ"
    );
    element["invoice_number"] = dataSet["invoice_number"];
    element["grn_file"] = dataSet["grn_file"];
  });
  return dataSet;
};

export default recievedItemCreateFromatter;
