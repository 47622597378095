import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const orderItemDeleteSlice = createApi({
    reducerPath: "orderItemDelete",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://brand-logistics.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        orderItemDelete: builder.mutation({
            query: ({ id, data }) => ({
                url: "/order-management/api/v1/orderitem/update?id=" + id,
                method: "PATCH",
                body: data,
            }),
        }),
    }),
});

export const { useOrderItemDeleteMutation } = orderItemDeleteSlice;
