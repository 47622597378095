const customerDataUpdateFormatter = (data, currentForm) => {
  const dataSet = data?.data || data;
  if (dataSet["id"]) {
    currentForm["customer_id"] = {id: dataSet["id"], label: dataSet["name"]};
    currentForm["placeholder_name"] = dataSet["name"]||dataSet["label"];
    currentForm["name"] = dataSet["name"]||dataSet["label"]
  }
  return currentForm;
};

export default customerDataUpdateFormatter;
