// @ts-nocheck
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "./authComponent/AuthenticatedRoute";
import UnAuthenticatedRoute from "./authComponent/UnAuthenticatedRoute";
import { mergeStore } from "./store/appSlice";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import jwt_decode from "jwt-decode";
import {
    Login,
    ForgotPassword,
    ResetPassword,
    Home,
    ShipmentRecieved,
    ShipmentRecievedList,
    ShipmentRecievedEntry,
    ShipmentQuality,
    ShipmentQualityList,
    ShipmentQualityItem,
    ShipmentPo,
    ShipmentPoList,
    ShipmentPoListEdit,
    StockOut,
    StockOutList,
    Products,
    Manufacturer,
    OrderList,
    OrderUpdate,
    OrderStatusUpdate,
    OrderCreate,
    ChannelPricingList,
    ChannelPricingListUpdate,
} from "./pages";

function App() {
    const dispatch = useDispatch();
    const auth_token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const email = localStorage.getItem("email");
    const phone = localStorage.getItem("phone");
    const authTokenstore = useSelector((states) => states?.appStore?.authToken);
    const userIdStore = useSelector((states) => states?.appStore?.userId);
    const userNameStore = useSelector((states) => states?.appStore?.username);
    const emailStore = useSelector((states) => states?.appStore?.email);
    const phoneStore = useSelector((states) => states?.appStore?.phone);

    if (userIdStore && !userId) {
        localStorage.setItem("userId", userIdStore);
        localStorage.setItem("userName", userNameStore || "");
        localStorage.setItem("email", emailStore || "");
        localStorage.setItem("phone", phoneStore || "");
    } else if (!userIdStore && userId) {
        var formatedData = {
            userId: userId,
            userName: userName || "",
            email: email || "",
            phone: phone || "",
        };
        dispatch(mergeStore(formatedData));
    }

    if (authTokenstore && !auth_token) {
        localStorage.setItem("authToken", authTokenstore);
    } else if (!authTokenstore && auth_token) {
        var formatedData = {
            authToken: auth_token,
        };
        dispatch(mergeStore(formatedData));
    }
    const authToken = authTokenstore ? authTokenstore : auth_token;
    if (authToken) {
        var decoded = jwt_decode(authToken);
        if (Date.now() > decoded.exp * 1000) {
            localStorage.clear();
        }
    } else {
        localStorage.clear();
    }

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                    <Routes>
                        <Route path="/login" element={<Login />}></Route>

                        <Route path="/forgot-password" element={<ForgotPassword />}></Route>

                        <Route path="/reset_password/:id" element={<ResetPassword />}></Route>

                        <Route
                            path="/"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Home />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/shipment/recieved"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ShipmentRecieved />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/shipment/recievedlist"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ShipmentRecievedList />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/shipment/recieved/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ShipmentRecievedEntry />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/shipment/quality"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ShipmentQuality />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/shipment/qualitylist"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ShipmentQualityList />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/shipment/quality/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ShipmentQualityItem />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/shipment/purchaseorder"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ShipmentPo />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/shipment/polist"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ShipmentPoList />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/shipment/polist/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ShipmentPoListEdit />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/stockout/create"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <StockOut />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/stockout"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <StockOutList />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/products"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Products />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/manufacturer"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <Manufacturer />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/orders"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <OrderList />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/orders/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <OrderUpdate />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/orderstatus/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <OrderStatusUpdate />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/orders/create"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <OrderCreate />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/pricing"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ChannelPricingList />
                                </AuthenticatedRoute>
                            }
                        ></Route>

                        <Route
                            path="/pricing/:id"
                            element={
                                <AuthenticatedRoute isAuthenticated={authToken}>
                                    <ChannelPricingListUpdate />
                                </AuthenticatedRoute>
                            }
                        ></Route>
                    </Routes>
                </SnackbarProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
