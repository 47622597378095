const cityListFormatter = (data, dataKey) => {
  const returnData = {};
  const dataSet = data ? JSON.parse(JSON.stringify(data)) : [];
  dataSet.forEach((element) => {
    element["label"] = element["city_name"];
  });
  returnData[dataKey] = [{ id: "", label: "--" }, ...dataSet];
  return returnData;
};

export default cityListFormatter;
