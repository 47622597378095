// @ts-nocheck

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PMGrid, TableWidget } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { usePoListQuery } from "../store/poListSlice";
import { default as poListFormatter } from "../transformations/poListFormatter";
function ShipmentRecieved() {
    const [poListSkip, setpoListSkip] = useState(true);
    const [poListParams, setpoListParams] = useState({});
    const poListQuery = usePoListQuery(poListParams, { skip: poListSkip });
    const dispatch = useDispatch();
    const poListData = useSelector((states) => states?.appStore?.poListData);

    const poListSuccess = (data) => {
        savepoListData(data);
    };
    useEffect(() => {
        if (poListQuery.isSuccess) {
            poListSuccess(poListQuery);
        } else if (poListQuery.isError) {
            poListSuccess(null);
        }
    }, [poListQuery]);
    const poList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpoListParams({
            po_status: data ? data["INPROCESS"] : "INPROCESS",
            order_by: data ? data["-created_at"] : "-created_at",
        });
        setpoListSkip(false);
    };

    const savepoListData = (data) => {
        var formatedData = {
            ...poListFormatter(data?.data || data, "poListData"),
        };
        dispatch(setStore(formatedData));
    };

    useEffect(() => {
        poList();
    }, []);

    const poListReFetch = (tableParams) => {
        setpoListParams({ po_status: "INPROCESS", order_by: "-created_at", ...tableParams });
    };
    return (
        <div className="builder_wrapper" data={{title:"Create Shipment"}}>
            <MenuWidget data={{title:"Shipments"}}>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Create shipment Against PO"
                        xs={12}
                        tableApi={poListData || []}
                        updateTable={poListReFetch}
                        columns={[
                            { field: "po_date", headerName: "PO Date",flex:0.6, type: "date" },
                            { field: "po_number", headerName: "PO Number", highlight: true },
                            {
                                field: "products",
                                headerName: "Product - Quantity",
                                type: "multiline",
                                flex: 1.5,
                                sortable: false,
                            },
                            {
                                field: "manufacturer_name",
                                headerName: "Supplier",
                                highlight: true,
                                flex: 1.4,
                                sortable: false,
                            },
                            {
                                field: "total_with_gst",
                                headerName: "PO Value",
                                type: "currency",
                                flex: 0.7,
                                sortable: false,
                            },
                            { field: "po_file", headerName: "Download", type: "download", flex: 0.6, sortable: false },
                            { field: "Create Shipment", headerName: "Create Shipment", type: "edit",flex:0.7, sortable: false },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ShipmentRecieved;
