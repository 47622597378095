// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, PMCard, Image, FormWidget } from "../lib";

import { useResetPasswordMutation } from "../store/resetPasswordSlice";
import { default as resetPasswordFormatter } from "../transformations/resetPasswordFormatter";
function ResetPassword() {
    const resetPasswordMutation = useResetPasswordMutation();
    const history = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Password Changed", { variant: "success" });
    };

    const pocreated = (data) => {
        history("/login/");
    };

    const resetPassword = async (data) => {
        var apiData = resetPasswordFormatter(data, id, enqueueSnackbar);

        if (apiData && apiData["password_check"]) {
            await resetPasswordMutation[0]({
                password: apiData["password"],
                domain: apiData["domain"],
                token: apiData["token"],
            }).unwrap();
        }
    };

    const loginFormSucess = (data) => {
        pocreated(data);
        showSuccessAlert(data);
    };
    useEffect(() => {
        if (resetPasswordMutation[1].isSuccess) {
            loginFormSucess(resetPasswordMutation[1]);
        }
        if (resetPasswordMutation[1].isError) {
            enqueueSnackbar(errorSerializer(resetPasswordMutation[1]), { variant: "error" });
        }
    }, [resetPasswordMutation]);

    const loginForm = (data) => {
        resetPassword(data);
    };

    return (
        <div className="builder_wrapper">
            <PMGrid
                container={true}
                background="#007493"
                sx={{ direction: "column", alignItems: "center", justifyContent: "center" }}
                item={true}
                minHeight="100vh"
            >
                <PMGrid item={true} container={true} md={3} direction="column" background="#FFFFFF">
                    <PMCard raised={true}>
                        <PMGrid container={true} rowSpacing={3} alignSelf="center">
                            <PMGrid
                                item={true}
                                xs={12}
                                container={true}
                                sx={{ "margin-top": 10 }}
                                justifyContent="center"
                            >
                                <Image
                                    image="https://www.currytreepastes.com/image/catalog/logo-main.png"
                                    md={6}
                                ></Image>
                            </PMGrid>

                            <PMGrid item={true} xs={12} justifyContent="center">
                                <FormWidget
                                    direction="column"
                                    header="Reset Password"
                                    Onsubmit={loginForm}
                                    fieldsets={[
                                        {
                                            direction: "column",
                                            fields: [
                                                {
                                                    label: "Password",
                                                    name: "password",
                                                    type: "password",
                                                    required: true,
                                                    fullWidth: true,
                                                },
                                                {
                                                    label: "Re-Enter Password",
                                                    name: "repassword",
                                                    type: "password",
                                                    required: true,
                                                    fullWidth: true,
                                                },
                                            ],
                                        },
                                    ]}
                                ></FormWidget>
                            </PMGrid>
                        </PMGrid>
                    </PMCard>
                </PMGrid>
            </PMGrid>
        </div>
    );
}
export default ResetPassword;
