// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useProductListQuery } from "../store/productListSlice";

function Products() {
    const productListData = useSelector((states) => states?.appStore?.productListData);
    const [productListSkip, setproductListSkip] = useState(true);
    const [productListParams, setproductListParams] = useState({});
    const productListQuery = useProductListQuery(productListParams, { skip: productListSkip });
    const history = useNavigate();
    const dispatch = useDispatch();

    const afterLogin = (data) => {
        history("/lead" + "/" + data["id"]);
    };

    const LeadTableClick = (data) => {
        afterLogin(data);
    };

    const saveProductListData = (data) => {
        var formatedData = {
            productListData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const productListSuccess = (data) => {
        saveProductListData(data);
    };
    useEffect(() => {
        if (productListQuery.isSuccess) {
            productListSuccess(productListQuery);
        } else if (productListQuery.isError) {
            productListSuccess(null);
        }
    }, [productListQuery]);
    const productList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductListSkip(false);
    };

    useEffect(() => {
        productList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Current Inventory"
                        showSerialNumber={false}
                        xs={12}
                        rows={productListData || []}
                        columns={[
                            { field: "sku", headerName: "SKU" },
                            { field: "name", headerName: "Product" },
                            { field: "manufacturer", headerName: "Manufacturer" },
                            { field: "in_stock", headerName: "In Stock" },
                            { field: "shipment", headerName: "Alloted to shipment" },
                            { field: "sale", headerName: "Avaiable for Sale" },
                        ]}
                        Onclick={LeadTableClick}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Products;
