const OrderformDataFormatter = (data, customerdata, emailData, phoneData) => {
  const dataSet = data;
  if(dataSet["customer"]){
    dataSet["placeholder_name"] = dataSet?.customer?.name;
    dataSet["name"] = null;
  }
  else if (dataSet["customer_id"]) {
    dataSet["name"] = null;
    dataSet["phone"] = null;
    dataSet["email"] = null
    if (typeof dataSet["customer_id"] == "object"){

      var customer_data = dataSet["customer_id"]

    }
    else{

      var customer_data = customerdata?.filter(
        (ele) => ele.id == dataSet["customer_id"]
      )[0];

    }
    // dataSet["name"] = customer_data?.name
    dataSet["placeholder_name"] = customer_data?.name
    
  } else {
    dataSet["placeholder_name"] = null;
  }
  if (dataSet["email_id"] && emailData) {
    const customer_data = emailData?.filter(
      (ele) => ele.id == dataSet["email_id"]
    )[0];
    dataSet["email_id"] = customer_data?.id
    dataSet["placeholder_email"] = customer_data?.email_address;
  } else {
    dataSet["placeholder_email"] = null;
  }
  if (dataSet["phone_id"] && phoneData) {
    const customer_data = phoneData?.filter(
      (ele) => ele.id == dataSet["phone_id"]
    )[0];
    dataSet["phone_id"] = customer_data?.id
    dataSet["placeholder_phone"] = customer_data?.phone_number;
  } else{
    dataSet["placeholder_phone"] = null;
  }
  return dataSet;
};

export default OrderformDataFormatter;
