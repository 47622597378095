const poListFormatter = (data, dataKey) => {
  const dataSet = {};
  dataSet[dataKey] = JSON.parse(JSON.stringify(data));
  if (dataSet[dataKey]["poitem"]) {
    dataSet[dataKey]["poitem"].forEach((element) => {
      element["po_item_id"] = element["id"];
      element["product_name"] = element["productvariant"]["product"]["name"];
      element["received_item_status"] = "Received";
      element["receiveditem"].forEach((recieved) => {
        element["quantity"] = element["quantity"] - (recieved["quantity"] || 0);
      });
    });
    dataSet[dataKey]["poitem"] = dataSet[dataKey]["poitem"].filter(
      (ele) => (ele["quantity"] > 0 && ele["poitem_status"]!="CANCELLED")
    );
  }
  return dataSet;
};

export default poListFormatter;
