import { useNavigate } from "react-router-dom";
import { PMMenu } from "../lib";

import { ReactNode } from "react";

export interface MenuWidgetProps {
    data?: Array<Object>;
    datamapper?: Object;
    onClick?: Function;
    children?: ReactNode;
}
export function MenuWidget(props: MenuWidgetProps) {
    const history = useNavigate();

    const MenuHomeNavigate = (data) => {
        history("" + "" + data["url"]);
    };

    const MenuHomeCLick = (data) => {
        MenuHomeNavigate(data);
    };

    const createComponent = (data, index) => {
        return (
            <PMMenu
                open={true}
                title={data
                    ? props.datamapper && props.datamapper["title"]
                        ? data[props.datamapper["title"]]
                        : data?.title
                    : ""}
                currentUrl={
                    data
                        ? props.datamapper && props.datamapper["currentUrl"]
                            ? data[props.datamapper["currentUrl"]]
                            : data?.currentUrl
                        : ""
                }
                logo="https://www.currytreepastes.com/image/catalog/logo-main.png"
                onClick={MenuHomeCLick}
                menuItems={[
                    { type: "Link", text: "Home", url: "/", icon: { icon: "home", text_color: "info", size: "large" } },
                    { type: "Divider" },
                    {
                        type: "Nested",
                        text: "Orders",
                        icon: { icon: "receipt_long", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Create New",
                                url: "/orders/create/",
                                icon: { icon: "edit_document", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "View All",
                                url: "/orders/",
                                icon: { icon: "view_list", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    {
                        type: "Nested",
                        text: "Pricing",
                        icon: { icon: "payments", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "View All",
                                url: "/pricing/",
                                icon: { icon: "view_list", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                    {
                        type: "Nested",
                        text: "Purchase Order",
                        icon: { icon: "shopping_bag", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Create New",
                                url: "/shipment/purchaseorder/",
                                icon: { icon: "edit_document", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "View All",
                                url: "/shipment/polist/",
                                icon: { icon: "view_list", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    {
                        type: "Nested",
                        text: "Shipments",
                        icon: { icon: "local_shipping", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Create New",
                                url: "/shipment/recieved/",
                                icon: { icon: "edit_document", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "View All",
                                url: "/shipment/recievedlist/",
                                icon: { icon: "view_list", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    {
                        type: "Nested",
                        text: "Stock Inwarding(QC)",
                        icon: { icon: "inventory_2", text_color: "info", size: "large" },
                        collapseItem: [
                            {
                                type: "Collapse",
                                text: "Create New",
                                url: "/shipment/quality/",
                                icon: { icon: "edit_document", text_color: "info", size: "large" },
                            },
                            {
                                type: "Collapse",
                                text: "View All",
                                url: "/shipment/qualitylist/",
                                icon: { icon: "view_list", text_color: "info", size: "large" },
                            },
                        ],
                    },
                    { type: "Divider" },
                ]}
            >
                {props.children}
            </PMMenu>
        );
    };

    return (
        <>
            {Array.isArray(props.data)
                ? props.data?.map((ele, index) => {
                      return createComponent(ele, index);
                  })
                : props.data
                ? createComponent(props.data, "1")
                : createComponent([], "1")}
        </>
    );
}
export default MenuWidget;
