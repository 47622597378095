const productPricingFormatter = (data, pricingData, pricingData2, datakey) => {
  const dataSet = JSON.parse(JSON.stringify(data?.data || data));
  var items = []
  dataSet["items"].map((element) => {
    element["product_name"] = element["product"]["name"];
    element["weight"] = element["weight"]+"g";
    pricingData["items"].forEach((ele) => {
      if (ele.product_variant_id == element.id)
        element[ele["channel"]] = ele["price"];
    });
    pricingData2["items"].forEach((ele) => {
      if (ele.product_variant_id == element.id)
        element[ele["channel"]] = ele["price"];
    });
    if (element.status == "Active"){
      items.push(element)
    }
  });
  const returnData = [];
  returnData[datakey] = items;
  return returnData;
};
export default productPricingFormatter;
