// @ts-nocheck

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { errorSerializer } from "../helperFn/errorSerializer";
import { FormWidget, PMGrid } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { usePoDetailsQuery } from "../store/poDetailsSlice";

import { jsonToFormData } from "../helperFn/formData";
import { useRecievedItemCreateMutation } from "../store/recievedItemCreateSlice";
import { default as poDetailsFormatter } from "../transformations/poDetailsFormatter";
import { default as recievedItemCreateFromatter } from "../transformations/recievedItemCreateFromatter";
function ShipmentRecievedEntry() {
    const poDetailsData = useSelector((states) => states?.appStore?.poDetailsData);
    const [poDetailsSkip, setpoDetailsSkip] = useState(true);
    const [apiRes, setApiRes] = useState(false);
    const [poDetailsParams, setpoDetailsParams] = useState({});
    const poDetailsQuery = usePoDetailsQuery(poDetailsParams, { skip: poDetailsSkip });
    const recievedItemCreateMutation = useRecievedItemCreateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const shipmentCreated = (data) => {
        history("/shipment/recievedlist/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Shipment Entry Created", { variant: "success" });
    };

    const recievedItemCreate = async (data) => {
        var apiData = recievedItemCreateFromatter(data);

        if (apiData["poitem"] && apiData["poitem"].length) {
            apiData["poitem"].forEach(async (apiData) => {
                if (apiData) {
                    await recievedItemCreateMutation[0](
                        jsonToFormData({
                            id: apiData["id"],
                            grn_file: apiData["grn_file"],
                            po_item_id: apiData["po_item_id"],
                            invoice_number: apiData["invoice_number"],
                            batch_no: apiData["batch_no"],
                            shipment_date: apiData["shipment_date"],
                            no_of_mono_carton: apiData["no_of_mono_carton"],
                            quantity: apiData["quantity"],
                            received_item_status: apiData["received_item_status"],
                        })
                    ).unwrap();
                }
            });
        }
    };

    const recievedItemCreateSuscess = (data) => {
        showSuccessAlert(data);
        shipmentCreated(data);
        setApiRes(false)
    };
    useEffect(() => {
        if (recievedItemCreateMutation[1].isSuccess) {
            recievedItemCreateSuscess(recievedItemCreateMutation[1]);
        }
        if (recievedItemCreateMutation[1].isError) {
            setApiRes(false)
            enqueueSnackbar(errorSerializer(recievedItemCreateMutation[1]), { variant: "error" });
        }
    }, [recievedItemCreateMutation]);

    const recievedItemCreateApi = (data) => {
        setApiRes(true)
        recievedItemCreate(data);
    };

    const savepoDetailsData = (data) => {
        var formatedData = {
            ...poDetailsFormatter(data?.data || data, "poDetailsData"),
        };
        dispatch(setStore(formatedData));
    };

    const poDetailsSuccess = (data) => {
        savepoDetailsData(data);
    };
    useEffect(() => {
        if (poDetailsQuery.isSuccess) {
            poDetailsSuccess(poDetailsQuery);
        } else if (poDetailsQuery.isError) {
            poDetailsSuccess(null);
        }
    }, [poDetailsQuery]);
    const poDetails = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpoDetailsParams({ id: id });
        setpoDetailsSkip(false);
    };

    useEffect(() => {
        poDetails();
    }, [id]);
    const watchFormChange = () =>{
        
    }
    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        direction="column"
                        header="Shipment Received"
                        defaultValues={poDetailsData || []}
                        Onsubmit={recievedItemCreateApi}
                        Onblur={watchFormChange}
                        cancelRedirect="/shipment/recieved"
                        isLoading={apiRes}
                        fieldsets={[
                            {
                                direction: "row",
                                grid: 6,
                                fields: [
                                    { label: "PO Number", name: "po_number", disabled: true },
                                    { label: "Enter Invoice Number", name: "invoice_number", type: "text", required: true },
                                    {
                                        label: "Enter Shipment Delivery Date",
                                        name: "shipment_date",
                                        type: "date",
                                        minDate: 0,
                                        required: true,
                                    },
                                    {
                                        label: "Upload GRN",
                                        name: "grn_file",
                                        type: "file",
                                        accept: "image/*, application/pdf",
                                    },
                                ],
                            },
                            {
                                direction: "row",
                                group: "poitem",
                                header: "Product Details",
                                count: poDetailsData?.poitem?.length,
                                delete: true,
                                grid: 3,
                                fields: [
                                    { label: "po item id", name: "po_item_id", type: "hidden" },
                                    { label: "received_item_status", name: "received_item_status", type: "hidden" },
                                    { label: "Product Name", name: "product_name", type: "text", disabled: true },
                                    {
                                        label: "Enter Quantity Received",
                                        name: "quantity",
                                        type: "text",
                                        required: true,
                                        validation: { min: 1, pattern: "^\\d+$" },
                                    },
                                    { label: "Enter Batch Number", name: "batch_no", type: "text", required: true },
                                    {
                                        label: "Enter No of Cartons",
                                        name: "no_of_mono_carton",
                                        type: "text",
                                        required: true,
                                        validation: { min: 1, pattern: "^\\d+$" },
                                    },
                                ],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ShipmentRecievedEntry;
