const productFormSubmitFormatter = (data, defaultFormData) => {
  const dataSet = data?.data || data;
  const formData = JSON.parse(JSON.stringify(defaultFormData));
  formData["products"].forEach((element) => {
    element["order_id"] = dataSet["id"];
    if (!checkIfValidUUID(element["id"])) {
      delete element["id"];
    }
  });
  return formData;
};

const checkIfValidUUID = (str) => {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
};
export default productFormSubmitFormatter;
