// @ts-nocheck

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormWidget, PMGrid, TableWidget } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { usePoListQuery } from "../store/poListSlice";

import { useChengePoStatusQuery } from "../store/chengePoStatusSlice";
import { default as poListFormatter } from "../transformations/poListFormatter";
function ShipmentPoList() {
    const [poListSkip, setpoListSkip] = useState(true);
    const [poListParams, setpoListParams] = useState({});
    const [filterParams, setFilterParams] = useState({po_number:""});
    const poListQuery = usePoListQuery(poListParams, { skip: poListSkip });
    const [chengePoStatusSkip, setchengePoStatusSkip] = useState(true);
    const [chengePoStatusParams, setchengePoStatusParams] = useState({});
    const chengePoStatusQuery = useChengePoStatusQuery(chengePoStatusParams, { skip: chengePoStatusSkip });
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const poListData = useSelector((states) => states?.appStore?.poListData);

    const poListSuccess = (data) => {
        savepoListData(data);
    };
    useEffect(() => {
        if (poListQuery.isSuccess) {
            poListSuccess(poListQuery);
        } else if (poListQuery.isError) {
            poListSuccess(null);
        }
    }, [poListQuery]);
    const poList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;
        if(data){

            setFilterParams(data)
        }
        let filter = {}
        if (data?.po_number && data?.po_number != ""){
            filter["po_number"] = data?.po_number
        }
        if (data?.created_at__gte && data?.created_at__gte != ""){
            filter["created_at__date_gte"] = data?.created_at__gte.split(" ")[0]
        }
        if (data?.created_at__lte && data?.created_at__lte != ""){
            filter["created_at__date_lte"] = data?.created_at__lte.split(" ")[0]
        }
        setpoListParams({ order_by: data ? data["-created_at"] : "-created_at", ...filter });
        setpoListSkip(false);
    };

    const reloadOnSuccess = () => {
        setTimeout(function () {
            window.location.reload();
        }, 1000);
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Email Sent Successfully", { variant: "success" });
    };

    const chengePoStatusSuccess = (data) => {
        showSuccessAlert(data);
        reloadOnSuccess(data);
    };
    useEffect(() => {
        if (chengePoStatusQuery.isSuccess) {
            chengePoStatusSuccess(chengePoStatusQuery);
        } else if (chengePoStatusQuery.isError) {
            chengePoStatusSuccess(null);
        }
    }, [chengePoStatusQuery]);
    const chengePoStatus = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        if (data && data["id"]) {
            setchengePoStatusParams({ id: data ? data["id"] : "id" });
            setchengePoStatusSkip(false);
        }
    };

    const savepoListData = (data) => {
        var formatedData = {
            ...poListFormatter(data?.data || data, "poListData"),
        };
        dispatch(setStore(formatedData));
    };

    const searchOrder = (data) => {
        console.log("searchOrder", data)
        poList(data)
    };
    useEffect(() => {
        poList();
        chengePoStatus();
    }, []);

    const poListReFetch = (tableParams) => {
        let filter = {}
        if (filterParams?.po_number && filterParams?.po_number != ""){
            filter["po_number"] = filterParams?.po_number
        }
        if (filterParams?.created_at__lte && filterParams?.created_at__lte != ""){
            filter["created_at__date_lte"] = filterParams?.created_at__lte.split(" ")[0]
        }
        if (filterParams?.created_at__gte && filterParams?.created_at__gte != ""){
            filter["created_at__date_gte"] = filterParams?.created_at__gte.split(" ")[0]
        }
        setpoListParams({ order_by: "-created_at",...filter, ...tableParams });
    };
    const clearFilter = () => {
        setFilterParams({po_number:"", created_at__gte:null, created_at__lte:null})
        poList()
    }
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{title:"View Purchase Order"}}>
                <PMGrid container={true} marginTop={2}>

                <FormWidget
                            direction="row"
                            bgColor="inherit"
                            header=""
                            showCancelButton={false}
                            showClearButton={true}
                            submitButtonLabel="Apply Filter"
                            Onsubmit={searchOrder}
                            Onclear={clearFilter}
                            defaultValues={filterParams}
                            fieldsets={[
                                {
                                    direction: "row",
                                fields:[
                                    {
                                    label: "Enter PO Number",
                                    md: "4",
                                    name: "po_number",
                                    type: "text",
                                    
                                },
                                    {
                                    label: "Start Date",
                                    md: "4",
                                    name: "created_at__gte",
                                    type: "date",
                                    
                                },
                                    {
                                    label: "End Date",
                                    md: "4",
                                    name: "created_at__lte",
                                    type: "date",
                                    
                                },
                            ],}
                            ]}
                        ></FormWidget>
                    <TableWidget
                        header="Purchase Orders"
                        xs={12}
                        tableApi={poListData || []}
                        showQuickFilter={false}
                        updateTable={poListReFetch}
                        columns={[
                            { field: "po_date", headerName: "PO Date", flex: 0.7, type: "date" },
                            { field: "po_number", headerName: "PO Number", flex: 1.2, highlight: true },
                            {
                                field: "manufacturer_name",
                                headerName: "Supplier",
                                highlight: true,
                                flex: 1.5,
                                sortable: false,
                            },
                            {
                                field: "products",
                                headerName: "Product(Qty)",
                                type: "multiline",
                                flex: 1.2,
                                sortable: false,
                            },
                            {
                                field: "total_with_gst",
                                headerName: "PO Value",
                                type: "currency",
                                flex: 1,
                                sortable: false,
                            },
                            { field: "po_status", headerName: "Status", align: "center", flex: 0.5, sortable: false },
                            { field: "po_file", headerName: "Download", type: "download", flex: 0.7, sortable: false },
                            {
                                field: "Send Email",
                                headerName: "Send Email",
                                type: "button",
                                flex: 0.8,
                                onclick: chengePoStatus,
                                condition: ["po_status", "Open"],
                            },
                            {
                                headerName: "Edit",
                                type: "edit",
                                flex: 0.5,
                                sortable: false,
                                condition: ["po_status", "Open"],
                            },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ShipmentPoList;
