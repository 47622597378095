const poListFormatter = (data, dataKey) => {
  const dataSet = {};

  const status = {
    OPEN: "Open",
    CLOSED: "Closed",
    INPROCESS: "In Process",
  };
  dataSet[dataKey] = JSON.parse(JSON.stringify(data));
  if (dataSet[dataKey] && dataSet[dataKey]["items"]) {
    dataSet[dataKey]["items"].forEach((element) => {
      element["manufacturer_name"] = element["manufacturer"]["name"];
      element["po_status"] = status[element["po_status"]];
      if (element["poitem"] && element["poitem"].length) {
        element["products"] = "";

        element["poitem"].forEach((item, index) => {
          if(item["poitem_status"]!="CANCELLED"){

            if (index > 0) {
              element["products"] += "\n";
            }
            element["products"] +=
              item["productvariant"]["product"]["name"] +
              " - " +
              item["quantity"];
          }
        });
      }
    });
  }
  return dataSet;
};

export default poListFormatter;
