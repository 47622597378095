// @ts-nocheck

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { errorSerializer } from "../helperFn/errorSerializer";
import { FormWidget, PMGrid } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { useManufacturerQuery } from "../store/manufacturerSlice";

import { useProductVariantListQuery } from "../store/productVariantListSlice";

import { useStocklocationListQuery } from "../store/stocklocationListSlice";

import { usePoDetailsQuery } from "../store/poDetailsSlice";

import { usePoItemDeleteMutation } from "../store/poItemDeleteSlice";
import { usePoPdfCreateQuery } from "../store/poPdfCreateSlice";
import { default as podeleteFormatter } from "../transformations/podeleteFormatter";

import { usePoItemPatchMutation } from "../store/poItemPatchSlice";

import { jsonToFormData } from "../helperFn/formData";
import { useCreatepoitemMutation } from "../store/createpoitemSlice";
import { usePoDetailsPatchMutation } from "../store/poDetailsPatchSlice";
import { default as updatepoitemFormatter } from "../transformations/updatepoitemFormatter";

import { useManufacturerDetailsQuery } from "../store/manufacturerDetailsSlice";
import { default as formDataFormatter } from "../transformations/formDataFormatter";
import { default as manufacturerDropDown } from "../transformations/manufacturerDropDown";
import { default as poFormFormatter } from "../transformations/poFormFormatter";
import { default as productVariantListFormatter } from "../transformations/productVariantListFormatter";
import { default as stocklocationListFormatter } from "../transformations/stocklocationListFormatter";
function ShipmentPoListEdit() {
    const defaultFormData = useSelector((states) => states?.appStore?.defaultFormData);
    const [manufacturerSkip, setmanufacturerSkip] = useState(true);
    const [manufacturerParams, setmanufacturerParams] = useState({});
    const manufacturerQuery = useManufacturerQuery(manufacturerParams, { skip: manufacturerSkip });
    const [productVariantListSkip, setproductVariantListSkip] = useState(true);
    const [productVariantListParams, setproductVariantListParams] = useState({});
    const productVariantListQuery = useProductVariantListQuery(productVariantListParams, {
        skip: productVariantListSkip,
    });
    const [stocklocationListSkip, setstocklocationListSkip] = useState(true);
    const [stocklocationListParams, setstocklocationListParams] = useState({});
    const stocklocationListQuery = useStocklocationListQuery(stocklocationListParams, { skip: stocklocationListSkip });
    const [poDetailsSkip, setpoDetailsSkip] = useState(true);
    const [apiRes, setApiRes] = useState(false);
    const [poDetailsParams, setpoDetailsParams] = useState({});
    const poDetailsQuery = usePoDetailsQuery(poDetailsParams, { skip: poDetailsSkip });
    const poItemDeleteMutation = usePoItemDeleteMutation();
    const [poPdfCreateSkip, setpoPdfCreateSkip] = useState(true);
    const [poPdfCreateParams, setpoPdfCreateParams] = useState({});
    const poPdfCreateQuery = usePoPdfCreateQuery(poPdfCreateParams, { skip: poPdfCreateSkip });
    const createpoitemMutation = useCreatepoitemMutation();
    const poItemPatchMutation = usePoItemPatchMutation();
    const poDetailsPatchMutation = usePoDetailsPatchMutation();
    const [manufacturerDetailsSkip, setmanufacturerDetailsSkip] = useState(true);
    const [manufacturerDetailsParams, setmanufacturerDetailsParams] = useState({});
    const manufacturerDetailsQuery = useManufacturerDetailsQuery(manufacturerDetailsParams, {
        skip: manufacturerDetailsSkip,
    });
    const history = useNavigate();
    const dispatch = useDispatch();
    const [formDataState, setformDataState] = useState([]);
    const manufacturerData = useSelector((states) => states?.appStore?.manufacturerData);
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const formdataUpadte = (data) => {
        const responseData = data?.data || data;
        setformDataState(responseData);
    };

    const manufacturerDetailsStore = (data) => {
        var formatedData = {
            ...formDataFormatter(data?.data || data, "defaultFormData"),
        };
        dispatch(setStore(formatedData));
    };

    const manufacturerSuccess3 = (data) => {
        manufacturerDetailsStore(data);
    };
    useEffect(() => {
        if (manufacturerDetailsQuery.isSuccess) {
            manufacturerSuccess3(manufacturerDetailsQuery);
        } else if (manufacturerDetailsQuery.isError) {
            manufacturerSuccess3(null);
        }
    }, [manufacturerDetailsQuery]);
    const manufacturerDetails = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;
        if (data && data["supplier"]) {
            setmanufacturerDetailsParams({ id: data ? data["supplier"] : "supplier" });
            setmanufacturerDetailsSkip(false);
        }
    };

    const watchFormChange = (data) => {
        manufacturerDetails(data);

        formdataUpadte(data);
    };

    const pocreated = (data) => {
        history("/shipment/polist/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Purchase Order Updated", { variant: "success" });
    };

    const pdfCreationSuccess = (data) => {
        pocreated(data);
        showSuccessAlert(data);
    };
    useEffect(() => {
        if (poPdfCreateQuery.isSuccess) {
            pdfCreationSuccess(poPdfCreateQuery);
        } else if (poPdfCreateQuery.isError) {
            pdfCreationSuccess(null);
        }
    }, [poPdfCreateQuery]);
    const poPdfCreate = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpoPdfCreateParams({ id: data ? data["purchase_order_id"] : "purchase_order_id" });
        setpoPdfCreateSkip(false);
    };

    const poItemPatch = async (data) => {
        var apiData = updatepoitemFormatter(data, formDataState);
        if (apiData["product_variant"] && apiData["product_variant"].length) {
            apiData["product_variant"].forEach(async (apiData) => {
                if (apiData) {
                    if (apiData["id"]) {
                        await poItemPatchMutation[0]({
                            id: apiData["id"],
                            data: {
                                purchase_order_id: apiData["purchase_order_id"],
                                product_variant_id: apiData["product_variant_id"],
                                quantity: apiData["quantity"],
                                rate: apiData["rate"],
                                poitem_status: apiData["poitem_status"],
                            },
                        }).unwrap();
                    } else {
                        await createpoitemMutation[0]({
                            purchase_order_id: apiData["purchase_order_id"],
                            product_variant_id: apiData["product_variant_id"],
                            quantity: apiData["quantity"],
                            rate: apiData["rate"],
                            poitem_status: apiData["poitem_status"],
                        }).unwrap();
                    }
                }
            });
        }
    };

    const poItemPatchSuscess = (data) => {
        poPdfCreate(data);
    };
    useEffect(() => {
        if (poItemPatchMutation[1].isSuccess) {
            poItemPatchSuscess(poItemPatchMutation[1]);
        }
        if (poItemPatchMutation[1].isError) {
            setApiRes(false)
            enqueueSnackbar(errorSerializer(poItemPatchMutation[1]), { variant: "error" });
        }
    }, [poItemPatchMutation]);

    const poDetailsPatch = async (data) => {
        var apiData = data;

        if (apiData) {
            await poDetailsPatchMutation[0]({
                id: apiData["id"],
                data: jsonToFormData({
                    po_date: apiData["po_date"],
                    manufacturer_id: apiData["supplier"],
                    delivery_warehouse_id: apiData["delivery_warehouse_id"],
                    po_status: apiData["po_status"],
                }),
            }).unwrap();
        }
    };

    const poDetailsPatchSuscess = (data) => {
        poItemPatch(data);
        setApiRes(false)
        poPdfCreate({"purchase_order_id":data?.data["id"]});
    };
    useEffect(() => {
        if (poDetailsPatchMutation[1].isSuccess) {
            poDetailsPatchSuscess(poDetailsPatchMutation[1]);
        }
        if (poDetailsPatchMutation[1].isError) {
            setApiRes(false)
            enqueueSnackbar(errorSerializer(poDetailsPatchMutation[1]), { variant: "error" });
        }
    }, [poDetailsPatchMutation]);

    const createpoName = (data) => {
        setApiRes(true)
        poDetailsPatch(data);
    };

    const showpoItemSuccessAlert = (data) => {
        enqueueSnackbar("Item Updated", { variant: "success" });
    };

    const poItemDelete = async (data) => {
        var apiData = podeleteFormatter(data);

        if (apiData && apiData["id"]) {
            await poItemDeleteMutation[0]({
                id: apiData["id"],
                data: { poitem_status: apiData["poitem_status"] },
            }).unwrap();
        }
    };

    const poItemDeleteSuccess = (data) => {
        showpoItemSuccessAlert(data);
    };
    useEffect(() => {
        if (poItemDeleteMutation[1].isSuccess) {
            poItemDeleteSuccess(poItemDeleteMutation[1]);
        }
        if (poItemDeleteMutation[1].isError) {
            setApiRes(false)
            enqueueSnackbar(errorSerializer(poItemDeleteMutation[1]), { variant: "error" });
        }
    }, [poItemDeleteMutation]);

    useEffect(() => {
        poItemDelete();
    }, []);

    const savepoDetailsData = (data) => {
        manufacturerDetails({data:{supplier:data?.data?.manufacturer_id}});
        var formatedData = {
            ...poFormFormatter(data?.data || data, "defaultFormData"),
        };
        dispatch(setStore(formatedData));
    };

    const poDetailsSuccess = (data) => {
        savepoDetailsData(data);
    };
    useEffect(() => {
        if (poDetailsQuery.isSuccess) {
            poDetailsSuccess(poDetailsQuery);
        } else if (poDetailsQuery.isError) {
            poDetailsSuccess(null);
        }
    }, [poDetailsQuery]);
    const poDetails = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpoDetailsParams({ id: id });
        setpoDetailsSkip(false);
    };

    const savestocklocationListData = (data) => {
        var formatedData = {
            ...stocklocationListFormatter(data?.data || data, "defaultFormData"),
        };
        dispatch(setStore(formatedData));
    };

    const stocklocationListSuccess = (data) => {
        savestocklocationListData(data);
    };
    useEffect(() => {
        if (stocklocationListQuery.isSuccess) {
            stocklocationListSuccess(stocklocationListQuery);
        } else if (stocklocationListQuery.isError) {
            stocklocationListSuccess(null);
        }
    }, [stocklocationListQuery]);
    const stocklocationList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setstocklocationListSkip(false);
    };

    const saveProductVariantListData = (data) => {
        var formatedData = {
            ...productVariantListFormatter(data?.data || data, "productVariantListData"),
        };
        dispatch(setStore(formatedData));
    };

    const productVariantListSuccess = (data) => {
        saveProductVariantListData(data);
    };
    useEffect(() => {
        if (productVariantListQuery.isSuccess) {
            productVariantListSuccess(productVariantListQuery);
        } else if (productVariantListQuery.isError) {
            productVariantListSuccess(null);
        }
    }, [productVariantListQuery]);
    const productVariantList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductVariantListSkip(false);
    };

    const saveManufacturerData = (data) => {
        var formatedData = {
            ...manufacturerDropDown(data?.data || data, "manufacturerData"),
        };
        dispatch(setStore(formatedData));
    };

    const manufacturerSuccess = (data) => {
        saveManufacturerData(data);
    };
    useEffect(() => {
        if (manufacturerQuery.isSuccess) {
            manufacturerSuccess(manufacturerQuery);
        } else if (manufacturerQuery.isError) {
            manufacturerSuccess(null);
        }
    }, [manufacturerQuery]);
    const manufacturer = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setmanufacturerSkip(false);
    };

    useEffect(() => {
        manufacturer();
        productVariantList();
        stocklocationList();
        poDetails();
    }, [id]);
    return (
        <div className="builder_wrapper">
            <MenuWidget currentUrl="/shipment/purchaseorder/" data={{title:"Edit Purchase Order"}}>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        
                        direction="column"
                        header="Purchase Order Update"
                        defaultValues={defaultFormData || []}
                        Onsubmit={createpoName}
                        isLoading={apiRes}
                        Onblur={watchFormChange}
                        cancelRedirect="/shipment/polist"
                        fieldsets={[
                            {
                                direction: "row",
                                fields: [
                                    { label: "PO Date", name: "po_date", type: "date", required: true },
                                    { label: "delivery_warehouse_id", name: "delivery_warehouse_id", type: "hidden" },
                                    { label: "po_status", name: "po_status", type: "hidden" },
                                    { label: "id", name: "id", type: "hidden" },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Supplier Details",
                                fields: [
                                    {
                                        label: "Select Supplier",
                                        name: "supplier",
                                        type: "select",
                                        required: true,
                                        options: manufacturerData,
                                    },
                                    { label: "Supplier Address", name: "address", type: "display" },
                                    { label: "Supplier Gstin", name: "gstin", type: "display" },
                                ],
                            },
                            {
                                direction: "row",
                                group: "product_variant",
                                additiontitle: "+ Add Product",
                                count: defaultFormData?.poitem?.length,
                                delete: true,
                                onDelete: poItemDelete,
                                header: "Product Details",
                                fields: [
                                    {
                                        label: "Select Product",
                                        md: "4",
                                        name: "product_variant_id",
                                        type: "select",
                                        required: true,
                                        options: productVariantListData,
                                    },
                                    {
                                        label: "Enter Quantity Required(ea)",
                                        name: "quantity",
                                        type: "text",
                                        required: true,
                                        validation: { min: 1, pattern: "^\\d+$" },
                                    },
                                    {
                                        label: "Enter Price (INR) (without GST)",
                                        name: "rate",
                                        type: "text",
                                        required: true,
                                        validation: { min: 1, pattern: "^\\d+(?:\\.\\d+)?$" },
                                        prefix: "₹",
                                    },
                                    { label: "id", name: "id", type: "hidden" },
                                ],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ShipmentPoListEdit;
