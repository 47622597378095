const orderStatsAggregateFormatter = (data, dataKey) => {

  const dataset = {}
  const cancelled_status = ["CANCELLED"]
  var cancelled = 0
  var completed = 0
  data.forEach(element => {
    if(element.order_status=="COMPLETE"){
      completed=element.count
    }
    else if(cancelled_status.includes(element.order_status)){
      cancelled+=element.count
    }
  });
  dataset[dataKey] = {"complete":completed,"cancelled":cancelled}
  return dataset;
};

export default orderStatsAggregateFormatter;
