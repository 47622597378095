const orderUpdateFormatter = (data, productList) => {
  const dataset = JSON.parse(JSON.stringify(data.data || data));
  dataset["payment_mode"] = dataset["payment"]["payment_mode"];
  dataset["payment_date"] = dataset["payment"]["payment_date"];
  dataset["payment_id"] = dataset["payment"]["id"];
  dataset["shipping_id"] = dataset["shipment"]["id"];
  dataset["shipping_method"] = dataset["shipment"]["shipping_method"];
  dataset["tracking_id"] = dataset["shipment"]["tracking_id"];
  if (dataset["orderitem"]) {
    dataset["orderitem"].map((ele) => {
      const product = productList?.items?.filter(
        (item) => item?.id == ele?.product_variant_id
      )[0];
      if (product) {
        ele["product_name"] = product["product"]["name"];
      }
      ele["per_item_price"] = (ele["price"]/ele["quantity"]).toFixed(2)
    });
  }
  return dataset;
};

export default orderUpdateFormatter;
