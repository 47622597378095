const orderItemListFormatter = (data) => {
  const dataSet = {};
  dataSet["products"] = data?.items
    ? JSON.parse(JSON.stringify(data?.items))
    : [];
  dataSet["products"].forEach(element => {
    element.price = element.price/element.quantity
    element.old_quantity = element.quantity
  });
  return dataSet;
};

export default orderItemListFormatter;
