const orderStatsTimeIntervalAggregateFormatter = (data, dataKey) => {
  const dataset = {}
  const channels = [
    "AMAZON","BIGBASKET","OFFLINE","WEBSITE"
  ]
  let labels = []
  let d =  []
  channels.forEach(channel => {
    let order_total = []
    
    data[channel].forEach(element=>{
      order_total.push(element[1])
      if (!labels.includes(element[0].toUpperCase())){
        labels.push((element[0].toUpperCase()))
      }
    })
    order_total = order_total?.map((item)=>{
      return parseInt(item)
    })
    console.log(order_total, "labels d")
    d.push({label: channel, data: order_total})
  })
  dataset[dataKey] = {"labels":labels,"dataset":d}
  return dataset;
};

export default orderStatsTimeIntervalAggregateFormatter;
