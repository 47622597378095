// @ts-nocheck

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormWidget, PMGrid, TableWidget } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { CircularProgress } from '@mui/material';
import Button from "@mui/material/Button";
import { useCustomerListQuery } from "../store/customerListSlice";
import { useOrderListQuery } from "../store/orderListSlice";
import { useSyncOrderQuery } from "../store/syncOrdersSlice";
import { default as customerListFormatter } from "../transformations/customerListFormatter";
import { default as orderListFormatter } from "../transformations/orderListFormatter";
function OrderList() {

    const [customerListSkip, setcustomerListSkip] = useState(true);
    const [customerListParams, setcustomerListParams] = useState({});
    const customerListQuery = useCustomerListQuery(customerListParams, { skip: customerListSkip });
    const [orderListSkip, setorderListSkip] = useState(true);
    const [syncOrderSkip, setsyncOrderSkip] = useState(true);
    const [orderListParams, setorderListParams] = useState({});
    const [syncOrderParams, setsyncOrderParams] = useState({});
    const [filterParams, setfilterParams] = useState({order_id:"",customer_id:"",created_at__lte:null,created_at__gte:null});
    const [apiRes, setApiRes] = useState(false);
    const orderListQuery = useOrderListQuery(orderListParams, { skip: orderListSkip });
    const syncOrderQuery = useSyncOrderQuery(syncOrderParams, { skip: syncOrderSkip });
    const dispatch = useDispatch();

    const customerListData = useSelector((states) => states?.appStore?.customerListData);
    const orderListData = useSelector((states) => states?.appStore?.orderListData);

    const orderListSuccess = (data) => {
        orderListListData(data);
    };
    useEffect(() => {
        if (orderListQuery.isSuccess) {
            orderListSuccess(orderListQuery);
        } else if (orderListQuery.isError) {
            orderListSuccess(null);
        }
    }, [orderListQuery]);

    useEffect(() => {
        if (syncOrderQuery.isSuccess) {
            
            orderListSuccess(syncOrderQuery);

        setsyncOrderSkip(true);
    } else if (syncOrderQuery.isError) {
        
        setsyncOrderSkip(true);
        }
    }, [syncOrderQuery]);
    
    const orderList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;
        if (data){

            setfilterParams(data)
        }
        console.log(data, "in order list")
        if (data) {

            const  filter = {}
            if (data?.order_id && data?.order_id != ""){
                filter["order_id"] = data["order_id"]
            }
            if (data?.customer_id && data?.customer_id != ""){
                filter["customer_id"] = data["customer_id"]?.id
            }
            if (data?.created_at__lte && data?.created_at__lte != ""){
                filter["created_at__date_lte"] = data["created_at__lte"].split(" ")[0]
            }
            if (data?.created_at__gte && data?.created_at__gte != ""){
                filter["created_at__date_gte"] = data["created_at__gte"].split(" ")[0]
            }
            setorderListParams({order_by:"-created_at",...filter});
            setorderListSkip(false);
        }
        else{
            setorderListParams({order_by:"-created_at"})
            setorderListSkip(false);

        }
    };
    useEffect(()=>{
        setApiRes(false)
    },[orderListData])
    
    const syncOrder = () => {
        setApiRes(true)
        setsyncOrderSkip(false);
    };
    const orderListListData = (data) => {
        var formatedData = {
            ...orderListFormatter(data?.data || data, "orderListData"),
        };
        dispatch(setStore(formatedData));
        
    };


    const customerListListData = (data) => {
        var formatedData = {
            ...customerListFormatter(data?.data || data, "customerListData"),
        };
        dispatch(setStore(formatedData));
    };

    const customerListSuccess = (data) => {
        customerListListData(data);
    };

    useEffect(() => {
        if (customerListQuery.isSuccess) {
            customerListSuccess(customerListQuery);
        } else if (customerListQuery.isError) {
            customerListSuccess(null);
        }
    }, [customerListQuery]);

    const customerList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcustomerListSkip(false);
    };

    
    useEffect(() => {
        orderList();
        customerList();
    }, []);
    console.log(filterParams,"filterparmas")
    const orderListReFetch = (tableParams) => {
        const  filter = {}
        console.log(filterParams)
        if (filterParams.order_id && filterParams.order_id != ""){
            filter["order_id"] = filterParams["order_id"]
        }
        if (filterParams.customer_id && filterParams.customer_id != ""){
            filter["customer_id"] = filterParams["customer_id"]?.id
        }
        if (filterParams.created_at__lte && filterParams.created_at__lte != ""){
            filter["created_at__date_lte"] = filterParams["created_at__lte"].split(" ")[0]
        }
        if (filterParams.created_at__gte && filterParams.created_at__gte != ""){
            filter["created_at__date_gte"] = filterParams["created_at__gte"].split(" ")[0]
        }
        setorderListParams({ order_by: "-created_at", ...filter , ...tableParams,});
        console.log(orderListParams,"orderlistparams", filter)
        setorderListSkip(false);
    };
    const fetchWebsiteOrders = () => {
        syncOrder()
    };
    const searchOrder = (data) => {
        console.log("searchOrder", data)
        orderList(data)
    };
    const clearFilter = () => {
        console.log("clearFilter", filterParams)
        setfilterParams({order_id:"",customer_id:"",created_at__lte:null,created_at__gte:null})
        
        orderList()
    };
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{title:"View Order"}}>
                <PMGrid container={true} marginTop={2}>

                    
                    <PMGrid container justifyContent="flex-end" sx={{"margin-top": 10}}>
                        <Button variant="contained" type="submit" color="info" disabled={apiRes} onClick={fetchWebsiteOrders} sx={{minWidth:150}}>
                            {apiRes ? <CircularProgress size={25} color="inherit"/>: "Sync Website Orders"}
                        </Button>
                    </PMGrid>
                    <FormWidget
                            direction="row"
                            bgColor="inherit"
                            header=""
                            showCancelButton={false}
                            cancelRedirect={"/orders"}
                            showClearButton={true}
                            submitButtonLabel="Apply Filter"
                            Onsubmit={searchOrder}
                            Onclear={clearFilter}
                            defaultValues={filterParams}
                            isLoading={apiRes}
                            fieldsets={[
                                {
                                    direction: "row",
                                fields:[
                                    {
                                    label: "Enter Order ID",
                                    md: "4",
                                    name: "order_id",
                                    type: "text",
                                    
                                },
                                    {
                                    label: "Search By Customer",
                                    md: "4",
                                    name: "customer_id",
                                    type: "autocomplete",
                                    
                                    options: customerListData,
                                },
                                    {
                                    label: "Start Date",
                                    md: "4",
                                    name: "created_at__gte",
                                    type: "date",
                                    
                                },
                                    {
                                    label: "End Date",
                                    md: "4",
                                    name: "created_at__lte",
                                    type: "date",
                                },
                            ],}
                            ]}
                        ></FormWidget>
                    <TableWidget
                        header="Orders List"
                        showQuickFilter={false}
                        showSerialNumber={false}
                        xs={12}
                        tableApi={orderListData || []}
                        updateTable={orderListReFetch}
                        columns={[
                            { field: "order_id", headerName: "Order ID", flex: 0.8 },
                            { field: "created_at", headerName: "Created Date", type: "date", flex: 0.7 },
                            { field: "customer_name", headerName: "Customer Name", flex: 1.5, sortable: false },
                            { field: "channel", headerName: "Channel", flex: 0.6, sortable: false },
                            { field: "order_status", headerName: "Order Status", flex: 0.8, highlight: true },
                            {
                                field: "total_amount",
                                headerName: "Total Order Value",
                                highlight: true,
                                flex: 0.8,
                                type: "currency",
                            },
                            { field: "invoice_file", headerName: "Invoice", flex: 0.8, type: "download" },
                            { field: "updated_at", headerName: "Last Updated On", flex: 0.8, type: "date" },
                            { headerName: "Edit", type: "edit", flex: 0.5, condition: ["editable", true] },
                            {
                                field: "Update Status",
                                headerName: "Update Status",
                                type: "edit",
                                url: "/orderstatus",
                                flex: 0.8,
                                condition: ["editable", false],
                            },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default OrderList;
