import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const stockOutSlice = createApi({
    reducerPath: "stockOut",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://brand-logistics.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        stockOut: builder.mutation({
            query: (data) => ({ url: "/inventory/api/v1/stockledger/create", method: "POST", body: data }),
        }),
    }),
});

export const { useStockOutMutation } = stockOutSlice;
