// @ts-nocheck

import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChartWidget, MetricWidget, PMGrid, PMHeader, TableWidget } from "../lib";
import { setStore } from "../store/appSlice";
import { useCurrentStockQuery } from "../store/currentStockSlice";
import { MenuWidget } from "./MenuWidget";

import { useOrderStatsQuery } from "../store/orderStatsSlice";

import { useOrderStatsAggregateQuery } from "../store/orderStatsAggregateSlice";
import { useOrderStatsChannelAggregateQuery } from "../store/orderStatsChannelAggregateSlice";
import { useOrderStatsTimeIntervalAggregateQuery } from "../store/orderStatsTimeIntervalAggregateSlice";
import { default as currentStockFormatter } from "../transformations/currentStockFormatter";
import { default as orderStatsAggregateFormatter } from "../transformations/orderStatsAggregateFormatter";
import { default as orderStatsChannelAggregateFormatter } from "../transformations/orderStatsChannelAggregateFormatter";
import { default as orderStatsFormatter } from "../transformations/orderStatsFormatter";
import { default as orderStatsTimeIntervalAggregateFormatter } from "../transformations/orderStatsTimeIntervalAggregateFormatter";
function Home() {
    const orderStatsData = useSelector((states) => states?.appStore?.orderStatsData);
    const orderStatsAggregateData = useSelector((states) => states?.appStore?.orderStatsAggregateData);
    const orderStatsTimeIntervalAggregateData = useSelector((states) => states?.appStore?.orderStatsTimeIntervalAggregateData);
    const orderStatsChannelAggregateData = useSelector((states) => states?.appStore?.orderStatsChannelAggregateData);
    const currentStockData = useSelector((states) => states?.appStore?.currentStockData);
    const [currentStockSkip, setcurrentStockSkip] = useState(true);
    const [currentStockParams, setcurrentStockParams] = useState({});
    const currentStockQuery = useCurrentStockQuery(currentStockParams, { skip: currentStockSkip });
    const [orderStatsSkip, setorderStatsSkip] = useState(true);
    const [orderStatsParams, setorderStatsParams] = useState({});
    const orderStatsQuery = useOrderStatsQuery(orderStatsParams, { skip: orderStatsSkip });
    const [orderStatsAggregateSkip, setorderStatsAggregateSkip] = useState(true);
    const [orderStatsAggregateParams, setorderStatsAggregateParams] = useState({});
    const [orderStatsChannelAggregateParams, setorderStatsChannelAggregateParams] = useState({});
    const [orderStatsTimeIntervalAggregateSkip, setorderStatsTimeIntervalAggregateSkip] = useState(true);
    const [orderStatsChannelAggregateSkip, setorderStatsChannelAggregateSkip] = useState(true);
    const orderStatsAggregateQuery = useOrderStatsAggregateQuery(orderStatsAggregateParams, {
        skip: orderStatsAggregateSkip,
    });
    const orderStatsTimeIntervalAggregateQuery = useOrderStatsTimeIntervalAggregateQuery({}, {
        skip: orderStatsTimeIntervalAggregateSkip,
    });
    const orderStatsChannelAggregateQuery = useOrderStatsChannelAggregateQuery(orderStatsChannelAggregateParams, {
        skip: orderStatsChannelAggregateSkip,
    });
    const dispatch = useDispatch();

    const orderStatsAggregateListData = (data) => {
        var formatedData = {
            ...orderStatsAggregateFormatter(data?.data || data, "orderStatsAggregateData"),
        };
        dispatch(setStore(formatedData));
    };

    const orderStatsTimeIntervalAggregateListData = (data) => {
        var formatedData = {
            ...orderStatsTimeIntervalAggregateFormatter(data?.data || data, "orderStatsTimeIntervalAggregateData"),
        };
        dispatch(setStore(formatedData));
    };
    const orderStatsChannelAggregateListData = (data) => {
        var formatedData = {
            ...orderStatsChannelAggregateFormatter(data?.data || data, "orderStatsChannelAggregateData"),
        };
        dispatch(setStore(formatedData));
    };

    const orderStatsAggregateSuccess = (data) => {
        orderStatsAggregateListData(data);
    };
    useEffect(() => {
        if (orderStatsAggregateQuery.isSuccess) {
            orderStatsAggregateSuccess(orderStatsAggregateQuery);
        } else if (orderStatsAggregateQuery.isError) {
            orderStatsAggregateSuccess(null);
        }
    }, [orderStatsAggregateQuery]);
    const orderStatsAggregate = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setorderStatsAggregateParams({
            metric: data ? data["order_status,order_status"] : "order_status,order_status",
            function: data ? data["count,"] : "count,",
            dimension: data ? data["order_status"] : "order_status",
        });
        setorderStatsAggregateSkip(false);
    };

    useEffect(() => {
        orderStatsAggregate();
        orderStatsTimeIntervalAggregate();
        orderStatsChannelAggregate();
        orderStats();

        currentStock();
    }, []);

    const orderStatsTimeIntervalAggregateSuccess = (data) => {
        orderStatsTimeIntervalAggregateListData(data);
    };
    useEffect(() => {
        if (orderStatsTimeIntervalAggregateQuery.isSuccess) {
            orderStatsTimeIntervalAggregateSuccess(orderStatsTimeIntervalAggregateQuery);
        } else if (orderStatsTimeIntervalAggregateQuery.isError) {
            orderStatsTimeIntervalAggregateSuccess(null);
        }
    }, [orderStatsTimeIntervalAggregateQuery]);
    const orderStatsTimeIntervalAggregate = () => {
        setorderStatsTimeIntervalAggregateSkip(false);
    };
    const orderStatsChannelAggregateSuccess = (data) => {

        orderStatsChannelAggregateListData(data);
    };
    useEffect(() => {
        if (orderStatsChannelAggregateQuery.isSuccess) {
            orderStatsChannelAggregateSuccess(orderStatsChannelAggregateQuery);
        } else if (orderStatsChannelAggregateQuery.isError) {
            orderStatsChannelAggregateSuccess(null);
        }
    }, [orderStatsChannelAggregateQuery]);
    const orderStatsChannelAggregate = (apiResponse) => {

        var data = apiResponse?.data || apiResponse;

        setorderStatsChannelAggregateParams({
            metric: data ? data["channel,channel"] : "channel,channel",
            function: data ? data["count,"] : "count,",
            dimension: data ? data["channel"] : "channel",
        });
        setorderStatsChannelAggregateSkip(false);
    };

    // useEffect(() => {
    // }, []);

    const orderStatsListData = (data) => {
        var formatedData = {
            ...orderStatsFormatter(data?.data || data, "orderStatsData"),
        };
        dispatch(setStore(formatedData));
    };

    const orderStatsSuccess = (data) => {
        orderStatsListData(data);
    };
    useEffect(() => {
        if (orderStatsQuery.isSuccess) {
            orderStatsSuccess(orderStatsQuery);
        } else if (orderStatsQuery.isError) {
            orderStatsSuccess(null);
        }
    }, [orderStatsQuery]);
    const orderStats = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setorderStatsParams({
            metric: data ? data["id,total_amount"] : "id,total_amount",
            function: data ? data["count,sum"] : "count,sum",
        });
        setorderStatsSkip(false);
    };

    // useEffect(() => {
    // }, []);

    const currentStockListData = (data) => {
        var formatedData = {
            ...currentStockFormatter(data?.data || data, "currentStockData"),
        };
        dispatch(setStore(formatedData));
    };

    const currentStockSuccess = (data) => {
        currentStockListData(data);
    };
    useEffect(() => {
        if (currentStockQuery.isSuccess) {
            currentStockSuccess(currentStockQuery);
        } else if (currentStockQuery.isError) {
            currentStockSuccess(null);
        }
    }, [currentStockQuery]);
    const currentStock = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcurrentStockSkip(false);
    };
    const matches = useMediaQuery('(min-width:1600px)');
    const width = window.innerWidth
    console.log(matches, "==============", window.innerWidth)
    // useEffect(() => {
    // }, []);
    console.log(orderStatsChannelAggregateData,"=====================")
    return (
        <div className="builder_wrapper">
            <MenuWidget data={{title:"Home"}}>

            <PMGrid container={true} marginTop={0}>
                    <PMGrid container={true} marginTop={0}>
                        <PMHeader header="Order Stats" marginTop={0} marginBottom={0} divider={false}></PMHeader>
                    </PMGrid>

                    <MetricWidget
                        xs={3}
                        metricIcon="shopping_cart"
                        header="Total Order"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        metricValue={orderStatsData?.count || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="payments"
                        metricValueColor="secondary.main"
                        metricIconColor="secondary"
                        header="Total Order Value"
                        metricValue={"₹" + orderStatsData?.sum || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="domain_verification"
                        metricValueColor="success.main"
                        metricIconColor="success"
                        header="Total Completed Order"
                        metricValue={orderStatsAggregateData?.complete || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="remove_shopping_cart"
                        metricValueColor="error"
                        metricIconColor="error"
                        header="Total Cancelled Order"
                        metricValue={orderStatsAggregateData?.cancelled || []}
                    ></MetricWidget>

                    {/* <PMGrid container={true} marginTop={0}>
                        <PMHeader header="Inventory" marginTop={0} marginBottom={0} divider={false}></PMHeader>
                    </PMGrid>

                    <MetricWidget
                        xs={3}
                        metricIcon="inventory"
                        header="Total Products"
                        metricValueColor="primary"
                        metricIconColor="primary"
                        metricValue={currentStockData?.total || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="trending_down"
                        metricValueColor="warning.main"
                        metricIconColor="warning"
                        header="Low Stock Products"
                        metricValue={currentStockData?.low_stock || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="layers_clear"
                        metricValueColor="error"
                        metricIconColor="error"
                        header="Out of Stock Products"
                        metricValue={currentStockData?.no_stock || []}
                    ></MetricWidget>

                    <MetricWidget
                        xs={3}
                        metricIcon="inventory"
                        metricValueColor="success.main"
                        metricIconColor="success"
                        header="Well Stocked Products"
                        metricValue={currentStockData?.high_stock || []}
                    ></MetricWidget> */}
                </PMGrid>
                <PMGrid container={true} marginTop={0} justifyContent="center">
                    {/* <PMGrid container={true} marginTop={0}>
                        <PMHeader header="Order Stats" marginTop={0} marginBottom={0} divider={false}></PMHeader>
                    </PMGrid> */}
                    <PMGrid container={true}  marginTop={0} justifyContent="center">

                    {orderStatsChannelAggregateData?.total ? (<ChartWidget
                        xs={width > 1200?3:4}
                        type="pie"
                        datasets={[(orderStatsChannelAggregateData?.dataset||[0])]}
                        labels={(orderStatsChannelAggregateData?.channels||[0])}
                        showLegend={true}
                        legendPosition="right"

                        showTooltip={true}
                        options={{ aspectRatio: 1 , innerValue:orderStatsChannelAggregateData?.total, plugins: {
                            title: {
                                display: true,
                                text: 'Order Count against channels',
                                position: 'bottom',
                                padding: {
                                    top: 0,
                                },
                                fullsize: true
                            }
                        }}}
                        centerTitle="Total Orders"
                        innerValue={orderStatsChannelAggregateData?.total||0}
                        textInside={true}
                        // spacing={5}
                        // borderWidth={0.1}
                        // borderRadius={8}
                        // innerGap="90%"
                        innerTextWidth={width>2500?1.8: width >= 1970 ? 1.9 : 2.3}
                        innerValuetWidth={width>2500?2.5: width >= 1970?2: 3.2}
                        innerTextAlign="center"
                        innerTextFont={width>2500?2.2: width>1970?1.9:width>1670?1.3:width>1526?1.2:width>1356?1.1:1}
                        textBaseline="bottom"
                        valueBaseline="bottom"

                    ></ChartWidget>):""}
                    
                    <ChartWidget
                        xs={width > 1200?9:8}
                        type="line"
                        datasets={(orderStatsTimeIntervalAggregateData?.dataset||[])}
                        labels={orderStatsTimeIntervalAggregateData?.labels||[]}
                        showLegend={true}
                        legendPosition="right"

                        showTooltip={true}
                        options={{ aspectRatio: 3, scales: {x: { grid: { display: false}},y: { grid: { display: false}}},legend: {
                            display: true,
                            position: 'bottom',

                        
                        } , plugins: {
                            title: {
                                display: true,
                                text: 'Last Six Months Order Values Against channels',
                                position: 'bottom',
                                padding: {
                                    top:10
                                }
                            }
                        },
                        borderWidth: 1
                    }
                    }
                        borderRadius={3}
                    ></ChartWidget>
                    </PMGrid>
                </PMGrid>

                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Current Inventory"
                        showSerialNumber={true}
                        xs={12}
                        rows={currentStockData?.items || []}
                        columns={[
                            { field: "product_name", headerName: "Product" },
                            {
                                field: "available",
                                headerName: "Available Stock",
                                type: "chip",
                                condition: [
                                    { rule: [-100000000000, 1], color: "error" },
                                    { rule: [1, 100], color: "warning" },
                                ],
                            },
                            { field: "pipeline", headerName: "Pipeline" },
                            { field: "locked_by_order", headerName: "Locked By Order" },
                            { field: "shipped", headerName: "Shipped" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Home;
