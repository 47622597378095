import jwt_decode from "jwt-decode";
import { Navigate } from 'react-router-dom';

const AuthenticatedRoute = ({ isAuthenticated, children }) => {
  if (isAuthenticated) {
    const decoded = jwt_decode(isAuthenticated);
    if (Date.now() > decoded["exp"] * 1000) {
        localStorage.clear();
        return <Navigate to="/login" replace />;
      }
  } else {
      localStorage.clear();
      return <Navigate to="/login" replace />;
  }
  
  return children;
};
export default AuthenticatedRoute