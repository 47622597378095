const customerAddressUpdateFormatter = (data, addressList, dataKey) => {
  const returnData = {};
  if (addressList){
    returnData[dataKey] = JSON.parse(JSON.stringify(addressList));
    const dataSet = JSON.parse(JSON.stringify(data));
    dataSet[
      "label"
    ] = `${dataSet["address"]||""} - ${dataSet["city"]||""} - ${dataSet["state"]||""}`;
    returnData[dataKey].push(dataSet);
    
  returnData[dataKey] = [{ id: "", label: "--" }, ...returnData[dataKey]];
  }
  else{
    returnData[dataKey] = [];

  }
  return returnData;
};

export default customerAddressUpdateFormatter;
