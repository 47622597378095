const orderAddressListFormatter = (data, orderData, dataKey) => {
  const addresses = data.data || data;
  const payment_address = addresses?.items?.filter(
    (ele) => ele.id == orderData["payment"]["payment_address_id"]
  )[0];
  const shipment_address = addresses?.items?.filter(
    (ele) => ele.id == orderData["shipment"]["shipment_address_id"]
  )[0];
  const returnData = {};
  returnData[dataKey] = { payment_address, shipment_address };
  return returnData;
};

export default orderAddressListFormatter;
