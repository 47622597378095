const emailformDataFormatter = (data, formData) => {
  const dataSet = data?.items ? JSON.parse(JSON.stringify(data?.items)) : [];
  const emailData = dataSet.filter((ele) => ele.id === formData["phone_id"])[0];
  if (emailData) {
    formData["placeholder_phone"] = emailData["phone_number"];
  }
  return formData;
};

export default emailformDataFormatter;
