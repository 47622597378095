import _ from "lodash";

const orderCreateFormatter = (data, defaultFormData) => {
  const dataSet = data?.data || data;
  const formData = JSON.parse(JSON.stringify(defaultFormData));
  const orderId = formData["id"];
  const returnData = _.merge({}, formData, dataSet);
  if (orderId) {
    returnData["id"] = orderId;
  }
  returnData["name"] = returnData?.customer_id?.name
  returnData["customer_id"] = returnData["customer_id"]?.id||returnData["customerData"]||defaultFormData?.customer_id;
  return returnData;
};

export default orderCreateFormatter;
