// @ts-nocheck

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PMGrid, TableWidget } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { useProductVariantListQuery } from "../store/productVariantListSlice";

import { usePricingList2Query } from "../store/pricingList2Slice";

import { usePricingListQuery } from "../store/pricingListSlice";
import { default as productPricingFormatter } from "../transformations/productPricingFormatter";
function ChannelPricingList() {
    const productPricingData = useSelector((states) => states?.appStore?.productPricingData);
    const [productVariantListSkip, setproductVariantListSkip] = useState(true);
    const [productVariantListParams, setproductVariantListParams] = useState({});
    const productVariantListQuery = useProductVariantListQuery(productVariantListParams, {
        skip: productVariantListSkip,
    });
    const [pricingList2Skip, setpricingList2Skip] = useState(true);
    const [pricingList2Params, setpricingList2Params] = useState({});
    const pricingList2Query = usePricingList2Query(pricingList2Params, { skip: pricingList2Skip });
    const [pricingListSkip, setpricingListSkip] = useState(true);
    const [pricingListParams, setpricingListParams] = useState({});
    const pricingListQuery = usePricingListQuery(pricingListParams, { skip: pricingListSkip });
    const dispatch = useDispatch();
    const pricingListData = useSelector((states) => states?.appStore?.pricingListData);
    const pricingList2Data = useSelector((states) => states?.appStore?.pricingList2Data);

    const productVariantListListData = (data) => {
        var formatedData = {
            ...productPricingFormatter(data?.data || data, pricingListData, pricingList2Data, "productPricingData"),
        };
        dispatch(setStore(formatedData));
    };

    const productVariantListSuccess = (data) => {
        productVariantListListData(data);
    };
    useEffect(() => {
        if (productVariantListQuery.isSuccess) {
            productVariantListSuccess(productVariantListQuery);
        } else if (productVariantListQuery.isError) {
            productVariantListSuccess(null);
        }
    }, [productVariantListQuery]);
    const productVariantList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductVariantListParams({ order_by: data ? data["-updated_at"] : "-updated_at" });
        setproductVariantListSkip(false);
    };

    const pricingList2SuccData = (data) => {
        var formatedData = {
            pricingList2Data: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const pricingList2Success = (data) => {
        pricingList2SuccData(data);
        productVariantList(data);
    };
    useEffect(() => {
        if (pricingList2Query.isSuccess) {
            pricingList2Success(pricingList2Query);
        } else if (pricingList2Query.isError) {
            pricingList2Success(null);
        }
    }, [pricingList2Query]);
    const pricingList2 = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpricingList2Params({ order_by: "-created_at", size: "100", page: "2" });
        setpricingList2Skip(false);
    };

    const pricingListListData = (data) => {
        var formatedData = {
            pricingListData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const pricingListSuccess = (data) => {
        pricingListListData(data);
        pricingList2(data);
    };
    useEffect(() => {
        if (pricingListQuery.isSuccess) {
            pricingListSuccess(pricingListQuery);
        } else if (pricingListQuery.isError) {
            pricingListSuccess(null);
        }
    }, [pricingListQuery]);
    const pricingList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpricingListParams({
            order_by: data ? data["-created_at"] : "-created_at",
            size: data ? data["100"] : "100",
            page: data ? data["1"] : "1",
        });
        setpricingListSkip(false);
    };

    useEffect(() => {
        pricingList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{title:"View Pricing"}}>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Product Pricing"
                        showSerialNumber={true}
                        xs={12}
                        rows={productPricingData || []}
                        columns={[
                            { field: "product_name", headerName: "Product", flex: 1.5, highlight: true },
                            { field: "weight", headerName: "Weight" },
                            { field: "max_retail_price", headerName: "MRP", type: "currency" },
                            { field: "WEBSITE", headerName: "Website", type: "currency" },
                            { field: "AMAZON", headerName: "Amazon", type: "currency" },
                            { field: "BIGBASKET", headerName: "BigBasket", type: "currency" },
                            { headerName: "Edit", type: "edit" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ChannelPricingList;
