const customerListFormatter = (data, dataKey) => {
  const returnData = {};
  const dataSet = data ? JSON.parse(JSON.stringify(data)) : [];
  dataSet.forEach((element, index) => {
    element["label"] = element["customer_id"]+"-"+element["name"];
  });
  let keys = ['name'];
  // let dataValue = dataSet.filter(
  //     (s => o => 
  //         (k => !s.has(k) && s.add(k))
  //         (keys.map(k => o[k]).join('|'))
  //     )
  //     (new Set)
  // );
  // let dataValue = removeDuplicates(dataSet)
  returnData[dataKey] = [{ id: "", label: "--" }, ...dataSet];
  return returnData;
};

export default customerListFormatter;
