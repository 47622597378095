const qualityFilesFormatter = (data, defaultFormData) => {
  const dataSet = data?.data || data;
  const formData = defaultFormData;
  formData["defective_images"]?.forEach((element) => {
    element["qualitycheck_id"] = dataSet["id"];
  });
  return formData;
};

export default qualityFilesFormatter;
