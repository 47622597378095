// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, FormWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useProductVariantListQuery } from "../store/productVariantListSlice";

import { useStocklocationListQuery } from "../store/stocklocationListSlice";

import { useStockOutMutation } from "../store/stockOutSlice";
import { default as stockOutFormatter } from "../transformations/stockOutFormatter";
import { default as productVariantListFormatter } from "../transformations/productVariantListFormatter";
import { default as stocklocationListFormatter } from "../transformations/stocklocationListFormatter";
function StockOut() {
    const defaultFormData = useSelector((states) => states?.appStore?.defaultFormData);
    const [productVariantListSkip, setproductVariantListSkip] = useState(true);
    const [productVariantListParams, setproductVariantListParams] = useState({});
    const productVariantListQuery = useProductVariantListQuery(productVariantListParams, {
        skip: productVariantListSkip,
    });
    const [stocklocationListSkip, setstocklocationListSkip] = useState(true);
    const [stocklocationListParams, setstocklocationListParams] = useState({});
    const stocklocationListQuery = useStocklocationListQuery(stocklocationListParams, { skip: stocklocationListSkip });
    const stockOutMutation = useStockOutMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [formDataState, setformDataState] = useState([]);
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const { enqueueSnackbar } = useSnackbar();

    const formdataUpadte = (data) => {
        const responseData = data?.data || data;
        setformDataState(responseData);
    };

    const watchFormChange = (data) => {
        formdataUpadte(data);
    };

    const pocreated = (data) => {
        history("/stockout/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Stock Updated", { variant: "success" });
    };

    const stockOut = async (data) => {
        var apiData = stockOutFormatter(data, formDataState);

        if (apiData["stockout"] && apiData["stockout"].length) {
            apiData["stockout"].forEach(async (apiData) => {
                if (apiData) {
                    await stockOutMutation[0]({
                        transaction_ref: apiData["transaction_ref"],
                        product_variant_id: apiData["product_variant_id"],
                        stock_out: apiData["stock_out"],
                        transaction_date: apiData["transaction_date"],
                        transaction_type: apiData["transaction_type"],
                    }).unwrap();
                }
            });
        }
    };

    const stockOutSuccess = (data) => {
        showSuccessAlert(data);
        pocreated(data);
    };
    useEffect(() => {
        if (stockOutMutation[1].isSuccess) {
            stockOutSuccess(stockOutMutation[1]);
        }
        if (stockOutMutation[1].isError) {
            enqueueSnackbar(errorSerializer(stockOutMutation[1]), { variant: "error" });
        }
    }, [stockOutMutation]);

    const stockOutApi = (data) => {
        stockOut(data);
    };

    const savestocklocationListData = (data) => {
        var formatedData = {
            ...stocklocationListFormatter(data?.data || data, "defaultFormData"),
        };
        dispatch(setStore(formatedData));
    };

    const stocklocationListSuccess = (data) => {
        savestocklocationListData(data);
    };
    useEffect(() => {
        if (stocklocationListQuery.isSuccess) {
            stocklocationListSuccess(stocklocationListQuery);
        } else if (stocklocationListQuery.isError) {
            stocklocationListSuccess(null);
        }
    }, [stocklocationListQuery]);
    const stocklocationList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setstocklocationListSkip(false);
    };

    const saveProductVariantListData = (data) => {
        var formatedData = {
            ...productVariantListFormatter(data?.data || data, "productVariantListData"),
        };
        dispatch(setStore(formatedData));
    };

    const productVariantListSuccess = (data) => {
        saveProductVariantListData(data);
    };
    useEffect(() => {
        if (productVariantListQuery.isSuccess) {
            productVariantListSuccess(productVariantListQuery);
        } else if (productVariantListQuery.isError) {
            productVariantListSuccess(null);
        }
    }, [productVariantListQuery]);
    const productVariantList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductVariantListSkip(false);
    };

    useEffect(() => {
        productVariantList();
        stocklocationList();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        direction="column"
                        header="Create Stock Out Entry"
                        defaultValues={defaultFormData || []}
                        Onsubmit={stockOutApi}
                        Onchange={watchFormChange}
                        fieldsets={[
                            {
                                direction: "row",
                                fields: [
                                    { label: "delivery_warehouse_id", name: "delivery_warehouse_id", type: "hidden" },
                                    {
                                        label: "Reference Number",
                                        name: "transaction_ref",
                                        type: "text",
                                        required: true,
                                    },
                                    {
                                        label: "Transaction Date",
                                        name: "transaction_date",
                                        type: "date",
                                        required: true,
                                    },
                                ],
                            },
                            {
                                direction: "row",
                                group: "stockout",
                                additiontitle: "+ Add Product",
                                header: "Product Details",
                                delete: true,
                                count: 1,
                                fields: [
                                    {
                                        label: "Product Name",
                                        md: "4",
                                        name: "product_variant_id",
                                        type: "select",
                                        required: true,
                                        options: productVariantListData,
                                    },
                                    {
                                        label: "Quantity",
                                        name: "stock_out",
                                        type: "text",
                                        required: true,
                                        validation: { min: 1, pattern: "^\\d+$" },
                                    },
                                ],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default StockOut;
