import _ from "lodash";

const orderReviewFormatter = (
  data,
  formDataState,
  shipmentformState,
  PaymentformState
) => {
  const dataSet = data?.data || data;
  const returnData = _.merge(
    {},
    dataSet,
    shipmentformState,
    PaymentformState,
    formDataState
    );
  Object.keys(returnData).forEach(
    (k) => returnData[k] == null && delete returnData[k]
  );
  return returnData;
};

export default orderReviewFormatter;
