import Grid, { GridProps } from "@mui/material/Grid";
import "./PMGrid.scss";

export interface PMGridProps{
  bgColor?: string
  grid?: number
}

const PMGrid = (props: PMGridProps & GridProps) => {
    const handleClick = () => {
    // if(props.Onclick){
    //   props.Onclick()
    // }
  }

  return (
    // <div onClick={()=>handleClick()} className={`button-${props.grid}`}>
      <Grid {...props} item className={`background-${props.bgColor}`}></Grid>
      // </div>
  );
};

PMGrid.defaultProps = {
    spacing: 2
}

export default PMGrid;