const paymentListFormatter = (data) => {
  
  const dataSet = data?.data || data;
  if (dataSet?.items?.length) {
    return dataSet?.items[0];
  }
  return {};
};

export default paymentListFormatter;
