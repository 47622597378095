const emailDataUpdateFormatter = (data, currentForm) => {
  const dataSet = data?.data || data;
  
  if (dataSet["id"]) {
    currentForm["email_id"] = dataSet["id"];
    currentForm["placeholder_email"] = dataSet["address"];
  }
  return currentForm;
};

export default emailDataUpdateFormatter;
