import _ from "lodash";

const paymentCreateFormatter = (
  data,
  defaultFormData,
  shipmentFormData,
  reviewFormData
) => {
  const dataSet = data?.data || data;
  const formData = JSON.parse(JSON.stringify(defaultFormData));
  const shipmentData = JSON.parse(JSON.stringify(shipmentFormData));
  const reviewData = JSON.parse(JSON.stringify(reviewFormData));
  const paymentId = shipmentData["id"];
  const returnData = _.merge(shipmentData, formData, dataSet, reviewData);
  returnData["order_id"] = dataSet["id"];
  returnData["customer_id"] = dataSet["customer_id"].id
  if (paymentId) {
    returnData["id"] = paymentId;
  }
  return returnData;
};

export default paymentCreateFormatter;
