const productCheckFormatter = (data, currentForm, enqueueSnackbar) => {
  const dataSet = data?.data || data;
  console.log(data, "dataset")
  let error = false;
  dataSet["products"]?.forEach((element, index) => {
    if ((element["old_quantity"] ? element["quantity"] - element["old_quantity"]:element["quantity"]) > element["product_available"]) {
      enqueueSnackbar(
        `${element["product_available"]} item${
          element["product_available"] > 1 ? "s" : ""
        } available in stock for ${element["product_name"]}${element["old_quantity"]?", Previous entered Quantity was "+element["old_quantity"]:""}`,
        { variant: "error" }
      );
      error = true;
    }
  });
  if (error) {
    return currentForm;
  }
  return ++currentForm;
};

export default productCheckFormatter;
