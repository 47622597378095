const manufacturerDropDown = (data, dataKey) => {
  const sendData = {};
  sendData[dataKey] = {};
  const dataSet = JSON.parse(JSON.stringify(data));
  Object.keys(dataSet).forEach((element) => {
    switch (element) {
      case "gstin_number":
        sendData[dataKey]["gstin"] = dataSet[element];
        break;

      case "address":
        sendData[dataKey]["address"] = dataSet[element]["address"];
        break;

      default:
        break;
    }
  });
  return sendData;
};

export default manufacturerDropDown;
