import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import DownloadIcon from '@mui/icons-material/Download';
import { Chip, CircularProgress } from '@mui/material';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import _ from "lodash";
import Moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { AutocompleteElement, CheckboxElement, DatePickerElement, DateTimePickerElement, FormContainer, MultiSelectElement, PasswordElement, SelectElement, SwitchElement, TextFieldElement, useForm, useWatch } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { PMGrid, PMText } from "../../pmcomponents";
import { BaseWidget, BaseWidgetProps } from "../BaseWidget";
import "./FormWidget.scss";

export interface FormWidgetProps extends BaseWidgetProps {
    text?: string;
    textColor?: string;
    icon?: string;
    defaultValues?: Object;
    fieldsets?: Array<any>;
    fullWidth?: boolean;
    Onsubmit?: Function;
    Onclear?: Function;
    Onchange?: Function;
    Onblur?: Function;
    submitButtonLabel?: string;
    isLoading: boolean
    showCancelButton?: boolean;
    cancelButtonLabel?: string;
    cancelButtonLable?:string;
    cancelRedirect?:string;
    showClearButton?:boolean;

}

const FileUpload = (props) => {
    const inputRef = useRef<HTMLInputElement>()
    const [file, setFile] = useState(null)
    const handleChange = (data) => {
        setFile(data.target.files[0])
        props.onChange(props.name, data.target.files[0])
    }
    const currentaValue = _.get(props?.fileFormData, props?.name)
    useEffect(() => {
        if(currentaValue){
            setFile(currentaValue)
        }
    }, [currentaValue])
    const handleDelete = (data) => {
        data.target.value = null
        if(inputRef && inputRef.current && inputRef.current["value"]){
            inputRef.current.value = null
        }
        props.onChange(props.name, null)
        setFile(null)
    }
    useEffect(() => {
        props.onChange(props?.name, null)
    }, [])
    const decodeUrltoString = (url) => {
        if(url){
            const parsedurl = new URL(url)
            return decodeURIComponent(parsedurl.pathname.split("/").pop())
        }
        return url
    }

    return (
        <div className="file-input" onClick = {() => inputRef.current?.click()}>
            <div>
                <input {...props} onChange={handleChange} ref={inputRef} name={`${props.name}_file`} style={{display: "none"}}></input>
                <CloudUploadOutlinedIcon fontSize='large' sx={{paddingRight: 1, opacity: 0.5}} />
                {!(file || props?.url) && <span style={{opacity: 0.5}}>{props?.label}</span>}
                {file && <Chip
                    sx={{maxWidth: "calc(100% - 30px)"}}
                    label={file?.name}
                    onClick={() => inputRef.current?.click()}
                    onDelete={handleDelete}/>
                }
                {(!file && props.url) && <Chip
                    sx={{maxWidth: "calc(100% - 30px)"}}
                    label={decodeUrltoString(props.url)}
                    component="a"
                    href={props.url}
                    onDelete={()=>{}}
                    clickable
                    deleteIcon={<DownloadIcon />} />
                }
            </div>
        </div>
    )
}

export const FormWidget = (props: FormWidgetProps) => {
    const {submitButtonLabel,showCancelButton,showClearButton, Onsubmit,Onclear,cancelRedirect, defaultValues, fullWidth, ...baseProps } = props;
    const [fieldsetCount, setFieldsetCount] = useState([])
    const [fieldSetDefaultValue, setFieldSetDefaultValue] = useState([])
    const [currentForm, setCurrentForm] = useState({})
    const [isOnblur, setIsOnblur] = useState(false)
    const [fileFormData, setFileFormData] = useState(new FormData())
    const formContext = useForm({
        defaultValues: {...defaultValues}
    })

    const history = useNavigate();
    const { watch, reset, control, formState: { isDirty, isValid }, handleSubmit, setValue, getValues } = formContext
    let timer = null;

    const formData = useWatch({control})

    const addValidationMsg = (validation) => {
        Object.keys(validation).forEach(type => {
            let value = validation[type].value || validation[type]
            switch (type) {
                case "min":
                    validation[type] = {value: value, message: `Value should be greater than ${value}`}
                    break;

                case "max":
                    validation[type] = {value: value, message: `Value should be less than ${value}`}
                    break;

                case "minLength":
                    validation[type] = {value: value, message: `Length should be greater than ${value}`}
                    break;

                case "maxLength":
                    validation[type] = {value: value, message: `Length should be less than ${value}`}
                    break;

                case "pattern":
                    validation[type] = {value: new RegExp(value), message: `Invalid value`}
                    break;

                default:
                    break;
            }
        })
        return validation
    }
    const getDate = (dateDelta) => {
        if(!isNaN(dateDelta)){
            let today = Moment().add(dateDelta, 'days')
            return today
        }
        return dateDelta
    }

    const checkValue = (data) => {
        let emptyValue = false
        if(data){
            Object.keys(data).forEach((ele)=>{
                if(ele){
                    emptyValue = true
                }
            })
        }
        return emptyValue
    }
    useEffect(() => {
        let formDataTemp = {...formData}
        if(formData && checkValue(formData)){
            if(fileFormData){
                formDataTemp = _.extend(formDataTemp,fileFormData)
            }

            if(props.Onblur  && (!_.isEqual(formDataTemp, props.defaultValues))){
                props.Onblur(formDataTemp)
            }
        }
        setIsOnblur(false)
    }, [isOnblur])
    useEffect(() => {
        let formDataTemp = {...formData}
        if(formData && checkValue(formData)){
            if(fileFormData){
                formDataTemp = _.extend(formDataTemp,fileFormData)
            }
            if(props.Onblur  && !_.isEqual(formDataTemp, props.defaultValues)){
                props.Onblur(formDataTemp)
            }
        }
        // setIsOnblur(false)
    }, [formData["customer_id"], formData["city_id"]?.id, formData["state_id"]?.id])
    const onCancel = () => {
        history(props?.cancelRedirect)
    }
    const onSubmit = (data) => {
        if (isValid && props.Onsubmit) {
            if(fileFormData)(
                data = _.merge(data, fileFormData)
            )
            props.Onsubmit(convertDate(data));
        }
    };
    const convertDate = (data) => {
        var returnData = JSON.parse(JSON.stringify(data))
        Object.keys(data).forEach(element => {
            if(data[element] instanceof Date){
                data[element] = Moment(data[element]).format('YYYY-MM-DD hh:mm')
            }
        });
        return data
    }
    useEffect(() => {
        let fieldsetCountTemp = [...fieldsetCount]
        props.fieldsets.map((fieldset, fsIndex) => {
            if(fieldset.group){
                fieldsetCountTemp[fsIndex] = fieldsetCountTemp[fsIndex] || fieldset.count
            }else{
                fieldsetCountTemp[fsIndex] = 1
            }
        })
        setFieldsetCount([...fieldsetCountTemp])
    }, [props.fieldsets])

    useEffect(() => {
        if(props?.defaultValues){
            if(Object.keys(props.defaultValues).length!==0){
                let currentValue = formContext.getValues()
                Object.keys(props.defaultValues).forEach(element => {
                    if(currentValue[element]!==props.defaultValues[element]){
                        currentValue[element] = props.defaultValues[element]
                    }
                });
                reset({...currentValue})
            }
        }
    }, [props.defaultValues])
    const removeFromField = (fsindex, index, group, callback) => {
        let currentValue = formContext.getValues()
        let fieldsetValue = null
        let fieldsetCountTemp = [...fieldsetCount]
        fieldsetCountTemp[fsindex] = fieldsetCountTemp[fsindex]-1
        
        
        if(currentValue[group] && currentValue[group][index]){
            fieldsetValue =  currentValue[group][index]
            currentValue[group].splice(index, 1)
            currentValue[group]?.forEach((element,index) => {
                Object.keys(element).forEach((key)=>{
                    if(!currentValue[group][index][key]){
                        currentValue[group][index][key] = ""
                    }
                })
            });
        }
        
        let filesetValue = null
        if(fileFormData && fileFormData[group] && fileFormData[group][index]){
            filesetValue = fileFormData[group][index]
            // fileFormData[group].splice(index, 1)
            let fileDataTemp = {...fileFormData}
            fileDataTemp[group].splice(index, 1)
            setFileFormData({...fileDataTemp})
            
        }

        if(callback){
            callback(fieldsetValue || filesetValue)
        }
        
        reset({...currentValue})
        props.Onblur(currentValue)
        setFieldsetCount([...fieldsetCountTemp])
    }
    const addFromField = (index) => {
        let fieldsetCountTemp = [...fieldsetCount]
        fieldsetCountTemp[index] = fieldsetCountTemp[index]+1
        setFieldsetCount([...fieldsetCountTemp])
    }

    const fileOnChange = (name, file) => {
        let fileFormDataTemp = {...fileFormData}
        let jsonNames = name.split(/\[|\].|\]\[/)
        assign(fileFormDataTemp, jsonNames, file)
        setFileFormData(fileFormDataTemp)
        setIsOnblur(true)
    }

    const assign = (obj, keyPath, value) => {
        let lastKeyIndex = keyPath.length-1;
        for (var i = 0; i < lastKeyIndex; ++ i) {
          let key = keyPath[i];
          if (!(key in obj)){
              if(keyPath[i+1] && !isNaN(keyPath[i+1])){
                obj[key] = []
              }else{
                  obj[key] = {}
              }
          }
          obj = obj[key];
        }
        obj[keyPath[lastKeyIndex]] = value;
     }
    
    const handleBlur = () => {
        setIsOnblur(true)
    }
    const onClear = () => {
        props?.Onclear()
    }
    return (
        <BaseWidget {...baseProps} md={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
            <FormContainer handleSubmit={handleSubmit(onSubmit)}  formContext={formContext}>
                <PMGrid
                    container
                    direction={props.direction || "column"}
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {props.fieldsets.map((fieldset, fsIndex) => {
                        let rows = []
                        if (fieldset.header) {
                            rows.push(
                                !fieldset?.displayon && <PMGrid>
                                    <PMText
                                    variant="body1"
                                    sx={{ fontWeight: "600", width: "100%" }}
                                    color={baseProps.headerColor}
                                    >
                                    {fieldset.header}
                                    </PMText>
                                </PMGrid>
                            );
                        }
                        for (let index = 0; index < fieldsetCount[fsIndex]; index++) {
                            rows.push(
                                <PMGrid
                                    key={`GRID-${fsIndex}-${index}`}
                                    container
                                    direction={fieldset.direction || "row"}
                                    spacing={2}
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    sx={
                                        fieldset?.delete ? {"padding-right": "30px"} : {}
                                    }
                                >
                                    {fieldset.fields.map((fieldItem, fIndex) => {
                                        let field = {...fieldItem}
                                        let fieldType: any = TextFieldElement;
                                        let fieldStyleProps = {}
                                        let gridSize = field.grid || fieldset.grid || 4
    
                                        if(field.minDate){
                                            field.minDate = getDate(field.minDate)
                                        }
                                        if(field.maxDate){
                                            field.maxDate = getDate(field.maxDate)
                                        }
                                        if(field["validation"]){
                                            field["validation"] = addValidationMsg(field["validation"])
                                        }
                                        if (props.fullWidth || fieldset.fullWidth || field.fullWidth) {
                                            field["fullWidth"] = true
                                            fieldStyleProps["width"] = "100%"
                                        }
                                        let fieldDefault = defaultValues? defaultValues[field.name] || "":""
                                        if(fieldset.group){
                                            field.name = `${fieldset.group}[${index}].${field.name}`
                                            field.disabled = (defaultValues[fieldset.group]?.length && defaultValues[fieldset.group]?.length > index ? defaultValues[fieldset.group][index][field?.disable] : false || false)
                                            fieldDefault = _.get(defaultValues, (field.name))
                                        }
                                        if (!field.inputProps) {field.inputProps = {}}
    
                                        if (field.prefix) {
                                            field.inputProps.startAdornment = (<InputAdornment position="start">{field.prefix}</InputAdornment>);
                                        }
    
                                        if (field.suffix) {
                                            field.inputProps.endAdornment = (
                                                <InputAdornment position="end">{field.suffix}</InputAdornment>
                                            );
                                        }
                                        let className = "formWidth"
    
                                        switch (field.type) {
                                            case "text":
                                            case "number":
                                                fieldType = TextFieldElement;
                                                field.inputProps["onBlur"] = handleBlur
                                                break;
                                            case "email":
                                                fieldType = TextFieldElement;
                                                field.inputProps["onBlur"] = handleBlur
                                                break;

                                            case "file":
                                                fieldType = FileUpload;
                                                field.onChange = fileOnChange
                                                field.url = fieldDefault
                                                field.fileFormData = fileFormData
                                                field.InputLabelProps={ shrink: true }
                                                field.inputProps["onChange"] = handleBlur
                                                // field.onChange = handleBlur
                                                break;
                                            case "autocomplete":
                                                fieldType = AutocompleteElement;
                                                // field.onChange = handleBlur
                                                // field.onClose = handleBlur
                                                // field.onInputChange = handleBlur
                                                field["onChange"] = {event: handleBlur}
                                                field.inputProps["onChange"] = {event:handleBlur}
                                                break;
                                            case "select":
                                                fieldType = SelectElement;
                                                field["onChange"] = handleBlur
                                                // field.inputProps["onChange"] = handleBlur
                                                break;
                                            case "multiselect":
                                                fieldType = MultiSelectElement;
                                                break;
                                            case "switch":
                                                fieldType = SwitchElement;
                                                break;
                                            case "checkbox":
                                                fieldType = CheckboxElement;
                                                fieldStyleProps = {}
                                                className=""
                                                field["onChange"] = handleBlur
                                                // field.inputProps["onBlur"] = handleBlur
                                                break;
                                            case "password":
                                                fieldType = PasswordElement;
                                                break;
                                            case "date":
                                                fieldType = DatePickerElement;
                                                field.inputFormat = "dd-MMM-yyyy"
                                                field["onChange"] = handleBlur
                                                field.inputProps["onChange"] = handleBlur
                                                break;
                                            case "datetime":
                                                fieldType = DateTimePickerElement;
                                                field.inputFormat = "dd-MMM-yyyy h:m a"
                                                field["onChange"] = handleBlur
                                                field.inputProps["onChange"] = handleBlur
                                                break;
                                            case "display":
                                                fieldType = TextFieldElement;
                                                field.disabled = true
                                                fieldStyleProps["display"] = (fieldDefault || fieldDefault==0)?"":"none"
                                                fieldStyleProps["& fieldset"] = {border: "none"}
                                                break;
                                            case "hidden":
                                                fieldType = TextFieldElement;
                                                fieldStyleProps["display"] = "none"
                                                break;
                                        }
                                        return (
                                            !field?.displayon &&
                                                <PMGrid md={gridSize} xs={12}  key={`${fIndex}-${index}`} sx={fieldStyleProps}>
                                                    {React.createElement(fieldType, {
                                                        ...field, className: className, InputProps:field.inputProps, minWidth:"100%"
                                                    })}
                                                </PMGrid>
                                        );
                                    })}
                                    {
                                        (fieldset?.delete && fieldsetCount[fsIndex]!=1) && (
                                            <IconButton className="deleteButton" aria-label="delete" color='error' onClick={()=>removeFromField(fsIndex, index, fieldset.group, fieldset?.onDelete)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )
                                    }
                                </PMGrid>
                            );
                        }
                        if(fieldset?.additiontitle){
                            rows.push(
                                <PMGrid container justifyContent="flex-end" sx={{"margin-top": 10}}>
                                    <Button variant="outlined" disabled={props?.isLoading} onClick={()=>addFromField(fsIndex)}>
                                        {fieldset?.additiontitle}
                                    </Button>
                                </PMGrid>
                            )
                        }
                        return rows
                    })}
                    <PMGrid container justifyContent="flex-end" sx={{"margin-top": 10}}>
                        {showCancelButton?(<Button variant="contained"  color="error" sx={{"margin-right":5}}  onClick={onCancel}>
                            {props.cancelButtonLabel}
                        </Button>):""} 
                        {showClearButton?(<Button variant="contained"  color="info" sx={{"margin-right":5}}  onClick={onClear}>
                            {"Clear"}
                        </Button>):""} 
                        <Button variant="contained" type="submit" disabled={props.isLoading} onClick={onSubmit} sx={{minWidth:150}}>
                            {props?.isLoading ? <CircularProgress size={25} color="inherit"/>: props.submitButtonLabel}
                        </Button>
                    </PMGrid>
                </PMGrid>
            </FormContainer>
            </LocalizationProvider>
        </BaseWidget>
    );
};

FormWidget.defaultProps = {
    direction: "column",
    submitButtonLabel: "Submit",
    fullWidth: false,
    cancelButtonLabel: "Cancel",  
    cancelRedirect: "/",                  
showCancelButton: true,
showClearButton: false,
};

export default FormWidget;
