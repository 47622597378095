
const currentStockFormatter = (data, dataKey) => {
  const dataSet = {};
  dataSet[dataKey] = JSON.parse(JSON.stringify(data));
  let items = []
  if (dataSet[dataKey]) {
    dataSet[dataKey]["items"].forEach((element) => {
      if (element["productvariant"]) {
        element["product_name"] = element["productvariant"]["product"]["name"];
      }
      element["locked_by_order"] = element["locked_by_order"] || 0;
      element["shipped"] = element["shipped"] || 0;
      element["available"] = element["available"] || 0;
      element["pipeline"] = element["pipeline"] || 0;
      if (element.productvariant.status == "Active"){
        items.push(element)
      }
    });
    dataSet[dataKey]["items"] = items
    dataSet[dataKey]["total"] = items?.length
    dataSet[dataKey]["low_stock"] =
      items.filter(
        (ele) => ele?.available < 100 && ele?.available > 0
      ).length || 0;
    dataSet[dataKey]["no_stock"] =
      items.filter((ele) => ele?.available <= 0).length ||
      0;
    dataSet[dataKey]["high_stock"] =
      items.filter((ele) => ele?.available >= 100).length ||
      0;
  }
  return dataSet;
};

export default currentStockFormatter;
