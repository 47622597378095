const orderStatsChannelAggregateFormatter = (data, dataKey) => {

  const datasets = {}
  console.log(data,"========channel")
  let response = {
    "AMAZON":0,
    "BIGBASKET":0,
    "OFFLINE":0,
    "WEBSITE":0,
  }
  data.forEach(element => {
    response[element.channel] = element.count
  });
  datasets[dataKey] = {channels:Object.keys(response), dataset: Object.values(response), total: Object.values(response).reduce((a,b)=>a+b,0)};
  return datasets
};

export default orderStatsChannelAggregateFormatter;
