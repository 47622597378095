const qualityCheckFormatter = (data, productList, pricingList, reviewFormData=null) => {
  const dataSet = data?.data || data;
  var total = 0;
  dataSet["products"].forEach((element, index) => {
    const products = productList.filter(
      (ele) => ele.id == element.product_variant_id
    )[0];
    if (products) {
      element["product_name"] = products["product"]["name"];
      element["product_available"] = products["stock"]["available"];
    }
    var item_price = undefined;
    if(reviewFormData?.orderitem){
      item_price = reviewFormData?.orderitem?.filter((item) => item.product_variant_id==element.product_variant_id)[0];
    }
    if (pricingList){

      const price = pricingList["items"].filter(
        (ele) => ele.product_variant_id == element.product_variant_id
      )[0];
      if (price && (!reviewFormData || (reviewFormData && reviewFormData.channel != "OFFLINE"))) {
        element["price"] = price["price"];
      }
    }
    if (element["quantity"]) {
      element["product_total"] =
        parseInt(element["quantity"]) * parseInt(element["price"]);
      total += element["product_total"];
    }
  });
  dataSet["order_total"] = total;
  return dataSet;
};

export default qualityCheckFormatter;
