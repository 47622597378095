const customerAddressListFormatter = (data, dataKey) => {
  const returnData = {};
  const dataSet = data?.items ? JSON.parse(JSON.stringify(data?.items)) : [];
  dataSet.forEach((element) => {
    element[
      "label"
    ] = `${element["address"]} - ${element["city"]} - ${element["state"]}`;
  });
  if(dataSet.length){

    returnData[dataKey] = [{ id: "", label: "--" }, ...dataSet];
  }
  else{
    returnData[dataKey] = [];

  }
  return returnData;
};

export default customerAddressListFormatter;
