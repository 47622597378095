// @ts-nocheck

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { errorSerializer } from "../helperFn/errorSerializer";
import { DetailWidget, FormWidget, MetricWidget, PMGrid } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { usePricingListQuery } from "../store/pricingListSlice";

import { useProductVariantDetailQuery } from "../store/productVariantDetailSlice";

import { usePricingUpdateMutation } from "../store/pricingUpdateSlice";
import { default as productVariantDetailFormatter } from "../transformations/productVariantDetailFormatter";
function ChannelPricingListUpdate() {
    const productVariantDetailData = useSelector((states) => states?.appStore?.productVariantDetailData);
    const pricingListData = useSelector((states) => states?.appStore?.pricingListData);
    const [pricingListSkip, setpricingListSkip] = useState(true);
    const [apiRes, setApiRes] = useState(false);
    const [pricingListParams, setpricingListParams] = useState({});
    const pricingListQuery = usePricingListQuery(pricingListParams, { skip: pricingListSkip });
    const [productVariantDetailSkip, setproductVariantDetailSkip] = useState(true);
    const [productVariantDetailParams, setproductVariantDetailParams] = useState({});
    const productVariantDetailQuery = useProductVariantDetailQuery(productVariantDetailParams, {
        skip: productVariantDetailSkip,
    });
    const pricingUpdateMutation = usePricingUpdateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [formDataState, setformDataState] = useState([]);
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const formdataUpadte = (data) => {
        const responseData = data?.data || data;
        setformDataState(responseData);
    };

    const watchFormChange = (data) => {
        formdataUpadte(data);
    };

    const pricingUpdatedNav = (data) => {
        history("/pricing/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Pricing Updated", { variant: "success" });
    };

    const pricingUpdate = async (data) => {
        var apiData = data;

        if (apiData["items"] && apiData["items"].length) {
            apiData["items"].forEach(async (apiData) => {
                if (apiData) {
                    await pricingUpdateMutation[0]({ id: apiData["id"], data: { price: apiData["price"] } }).unwrap();
                }
            });
        }
    };

    const pricingUpdateSuccess = (data) => {
        showSuccessAlert(data);
        pricingUpdatedNav(data);
        setApiRes(false)
    };
    useEffect(() => {
        if (pricingUpdateMutation[1].isSuccess) {
            pricingUpdateSuccess(pricingUpdateMutation[1]);
        }
        if (pricingUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(pricingUpdateMutation[1]), { variant: "error" });
        }
    }, [pricingUpdateMutation]);

    const pricingUpdateApi = (data) => {
        setApiRes(true)
        pricingUpdate(data);
    };

    const saveproductVariantDetailData = (data) => {
        var formatedData = {
            ...productVariantDetailFormatter(data?.data || data, "productVariantDetailData"),
        };
        dispatch(setStore(formatedData));
    };

    const productVariantDetailSuccess = (data) => {
        saveproductVariantDetailData(data);
    };
    useEffect(() => {
        if (productVariantDetailQuery.isSuccess) {
            productVariantDetailSuccess(productVariantDetailQuery);
        } else if (productVariantDetailQuery.isError) {
            productVariantDetailSuccess(null);
        }
    }, [productVariantDetailQuery]);
    const productVariantDetail = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setproductVariantDetailParams({ id: id });
        setproductVariantDetailSkip(false);
    };

    const savepricingListData = (data) => {
        var formatedData = {
            pricingListData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const pricingListSuccess = (data) => {
        savepricingListData(data);
    };
    useEffect(() => {
        if (pricingListQuery.isSuccess) {
            pricingListSuccess(pricingListQuery);
        } else if (pricingListQuery.isError) {
            pricingListSuccess(null);
        }
    }, [pricingListQuery]);
    const pricingList = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpricingListParams({ product_variant_id: id });
        setpricingListSkip(false);
    };

    useEffect(() => {
        pricingList();
        productVariantDetail();
    }, [id, id]);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{title:"Edit Price"}}>
                <PMGrid container={true} direction="row" xs={12}>
                    <PMGrid container={true} irection="column" xs={6}>
                        <MetricWidget
                            metricIcon="inventory"
                            header="Products"
                            md={12}
                            metricValueColor="primary"
                            metricIconColor="primary"
                            metricValue={productVariantDetailData?.product_name || []}
                        ></MetricWidget>

                        <DetailWidget
                            direction="row"
                            header="Product Information"
                            md={12}
                            data={productVariantDetailData || []}
                            fields={[
                                { label: "Available", key: "available" },
                                { label: "Pipeline", key: "pipeline" },
                                { label: "Blocked", key: "locked_by_order" },
                                { label: "MRP", key: "max_retail_price", type: "currency" },
                                { label: "Weight", key: "weight" },
                            ]}
                        ></DetailWidget>
                    </PMGrid>

                    <PMGrid container={true} direction="row" xs={6}>
                        <FormWidget
                            direction="column"
                            header="Update Pricing"
                            defaultValues={pricingListData || []}
                            Onsubmit={pricingUpdateApi}
                            isLoading={apiRes}
                            Onblur={watchFormChange}
                            cancelRedirect="/pricing"
                            fieldsets={[
                                {
                                    direction: "row",
                                    group: "items",
                                    grid: 6,
                                    count: pricingListData?.total,
                                    fields: [
                                        { name: "channel", type: "display" },
                                        {
                                            label: "Price",
                                            name: "price",
                                            type: "text",
                                            required: true,
                                            validation: { min: 1, pattern: "^\\d+(?:\\.\\d+)?$" },
                                        },
                                        { label: "id", name: "id", type: "hidden" },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    </PMGrid>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default ChannelPricingListUpdate;
