import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, ChartType, Legend, LinearScale, LineElement, PointElement, registerables, Tooltip } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { BaseWidget, BaseWidgetProps } from "../BaseWidget";

ChartJS.register(ArcElement, PointElement, LineElement, BarElement, CategoryScale, LinearScale, ...registerables);

export interface ChartWidgetProps {
    type?: ChartType
    datasets: Array<any>
    labels?: Array<any>
    showLegend: boolean
    showTooltip: boolean
    legendPosition: string
    legendAlign: string,
    options?: any
    centerTitle: string
    borderWidth: number,
    spacing: number,
    borderRadius: number,
    borderAlign: string,
    innerGap: string,
    innerTextWidth?: number
    innerValuetWidth?: number
    innerTextAlign?: string
    innerTextFont?: number
    textBaseline?: string
    textInside?: boolean
    innerValue?: number,
}

export const ChartWidget = (props: ChartWidgetProps & BaseWidgetProps) => {
    const { showLegend, showTooltip, ...baseProps } = props;

    let baseColors: Array<string> = [
        '#db6d6f',
        '#4fae54',
        '#f0be42',
        '#0576a6',
        '#9966FF',
        '#FF9F40',
        '#d7b088'
    ]
    var numColors: number = baseColors.length;

    var borderColors: string | Array<string> = baseColors[0];
    var backgroundColors: string | Array<string> = borderColors + "CC";

    if (!props.type) {
        return (
            <BaseWidget {...baseProps}>
                {"Invalid Chart Type"}
            </BaseWidget>
        )
    }


    // Register plugins
    if (!props.options.plugins) {
        props.options.plugins = {}
    }

    if (props.showLegend) {
        ChartJS.register(Legend);
        props.options.plugins["legend"] = {
            align: props?.legendAlign || "center",
            position: props?.legendPosition || "chartArea",
            maxHeight: 40
        }
        if (["pie", "doughnut"].includes(props.type)) {
            props.options.plugins.legend.labels = {
                usePointStyle: true,
                pointStyle: "rectRounded",
                generateLabels: (chart) => {
                    const datasets = chart.data.datasets;
                    return datasets[0].data.map((data, i) => ({
                        text: `${chart.data.labels[i]} (${data})`,
                        fillStyle: datasets[0].backgroundColor[i],
                    }))
                }
            }
        }
    }

    if (props.showTooltip) {
        ChartJS.register(Tooltip);
    }


    //to calculate total of datasxets values

    function myFunc(total, num) {
        return total + num;
    }

    if (["doughnut"].includes(props.type)) {

        var sumValue = props?.datasets[0]?.reduce(myFunc)
        console.log(sumValue, props?.datasets[0])
    }


    //plugins for dougnut to render text and value inside 
    console.log(sumValue,props.innerValue,props.datasets, "===============sumvalue")
    const plugins = [
        {
            id: 'inner text doghnut',
            beforeDraw: function (chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                ctx.textAlign = props?.innerTextAlign;
                ctx.font = props?.innerTextFont + "em sans-serif";
                ctx.textBaseline = props?.textBaseline;
                ctx.fillAlign = 'center';
                var text = props?.centerTitle,
                    textX = Math.round((width - ctx.measureText(text).width) / props.innerTextWidth),
                    textY = height / 1.7;
                var value = props?.innerValue,
                    valueX = Math.round((width - ctx.measureText(value).width) / props?.innerValuetWidth),
                    valueY = height / 2.1;
                ctx.fillText(text, textX, textY);
                ctx.fillText(value, valueX, valueY);
                ctx.save();
            },
            afterDraw: function(chart) {
				if (chart.tooltip?._active && chart.tooltip?._active.length) {
                var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
                ctx.restore();
                ctx.textAlign = props?.innerTextAlign;
                ctx.font = props?.innerTextFont + "em sans-serif";
                ctx.textBaseline = props?.textBaseline;
                ctx.fillAlign = 'center';
                var text = props?.centerTitle,
                    textX = Math.round((width - ctx.measureText(text).width) / props.innerTextWidth),
                    textY = height / 1.7;
                var value = props?.innerValue,
                    valueX = Math.round((width - ctx.measureText(value).width) / props?.innerValuetWidth),
                    valueY = height / 2.1;
                ctx.fillText(text, textX, textY);
                ctx.fillText(value, valueX, valueY);
                ctx.save();
                ctx.restore();
                }
            }
        }
    ]



    // If labels are not defined, autogenerate them using number of datapoints
    var labels = props.labels;
    if (!labels) {
        labels = props.datasets[0].map((value, index) => index + 1);
    }

    // Create datasets in chartsjs format
    var datasets = props.datasets.map((dataset, index) => {
        if (["pie", "doughnut"].includes(props.type)) {
            // Use multiple colors for some chart types
            backgroundColors = dataset.map((value, innerIndex) => baseColors[innerIndex % numColors] + "CC") // Add 80% alpha in background color
            borderColors = dataset.map((value, innerIndex) => baseColors[innerIndex % numColors])
        } else {
            // Use single color for some types like line chart
            borderColors = baseColors[0];
            backgroundColors = borderColors + "CC";
        }

        // Cycle colors for multiple datasets
        baseColors.push(baseColors.shift());

        return {
            label: dataset?.label || "Dataset " + (index + 1),
            data: dataset?.data || dataset,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: props?.borderWidth,
            spacing: props?.spacing,
            borderRadius: props?.borderRadius,
            cutout: props?.innerGap,
        }
    })

    var chartData = {
        labels: labels,
        datasets: datasets
    }

    return (
        <BaseWidget {...baseProps}>
            <Chart type={props.type} options={props.options} height={1/2} plugins={props.textInside == true && ["doughnut"].includes(props.type) ? plugins : null} data={chartData} />
        </BaseWidget>
    );
};

ChartWidget.defaultProps = {
    showLegend: false,
    showTooltip: false,
    options: {
        strokeWidth: 1,
        shape: "rounded"
    },
    textInside: false
}

export default ChartWidget;