import Typography, { TypographyProps } from "@mui/material/Typography";
import Divider from '@mui/material/Divider';

import PMGrid from "../pmgrid";

export interface PMHeaderProps {
    header: string
    variant?: any
    color?: any
    marginTop?:number
    marginBottom?:number
    divider?:boolean
}

export const PMHeader = (props: PMHeaderProps) => {
    let styleProps: object = {
        color: props.color
    }

    return (
        <PMGrid marginTop={props.marginTop} marginBottom={props.marginBottom}>
            <Typography gutterBottom variant={props.variant} sx={styleProps}>{props.header}</Typography>
            {props.divider?<Divider/>:""}
            {/* <Divider /> */}
        </PMGrid>
    );
};

PMHeader.defaultProps = {
    variant: "h5",
    color: "primary.main",
    marginTop: 4,
    marginBottom: 2,
    divider: true,
}

export default PMHeader;