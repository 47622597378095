import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const recievedItemListSlice = createApi({
    reducerPath: "recievedItemList",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://brand-logistics.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        recievedItemList: builder.query({
            query: (data) => ({ url: "/inventory/api/v1/receiveditem/list", params: data, method: "GET" }),
        }),
    }),
});

export const { useRecievedItemListQuery } = recievedItemListSlice;
