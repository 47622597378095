// @ts-nocheck

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStore, mergeStore } from "../store/appSlice";
import { useSnackbar } from "notistack";
import { errorSerializer } from "../helperFn/errorSerializer";
import { PMGrid, TableWidget } from "../lib";
import { MenuWidget } from "./MenuWidget";

import { useStockLedgerQuery } from "../store/stockLedgerSlice";
import { default as stockLedgerFormatter } from "../transformations/stockLedgerFormatter";
function StockOutList() {
    const [stockLedgerSkip, setstockLedgerSkip] = useState(true);
    const [stockLedgerParams, setstockLedgerParams] = useState({});
    const stockLedgerQuery = useStockLedgerQuery(stockLedgerParams, { skip: stockLedgerSkip });
    const dispatch = useDispatch();
    const stockLedgerData = useSelector((states) => states?.appStore?.stockLedgerData);

    const stockLedgerSuccess = (data) => {
        stockLedgerListData(data);
    };
    useEffect(() => {
        if (stockLedgerQuery.isSuccess) {
            stockLedgerSuccess(stockLedgerQuery);
        } else if (stockLedgerQuery.isError) {
            stockLedgerSuccess(null);
        }
    }, [stockLedgerQuery]);
    const stockLedger = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setstockLedgerParams({
            transaction_type: data ? data["Sale"] : "Sale",
            order_by: data ? data["-created_at"] : "-created_at",
        });
        setstockLedgerSkip(false);
    };

    const stockLedgerListData = (data) => {
        var formatedData = {
            ...stockLedgerFormatter(data?.data || data, "stockLedgerData"),
        };
        dispatch(setStore(formatedData));
    };

    useEffect(() => {
        stockLedger();
    }, []);

    const stockLedgerReFetch = (tableParams) => {
        setstockLedgerParams({ transaction_type: "Sale", order_by: "-created_at", ...tableParams });
    };
    return (
        <div className="builder_wrapper">
            <MenuWidget>
                <PMGrid container={true} marginTop={2}>
                    <TableWidget
                        header="Previous Stock Out Entries"
                        showSerialNumber={true}
                        xs={12}
                        tableApi={stockLedgerData || []}
                        updateTable={stockLedgerReFetch}
                        columns={[
                            { field: "transaction_date", headerName: "Transaction Date", type: "date" },
                            { field: "transaction_ref", headerName: "Transaction Ref" },
                            { field: "product_name", headerName: "Product", highlight: true, sortable: false },
                            { field: "stock_out", headerName: "Stock Out Quantity" },
                            { field: "initial_stock", headerName: "Initial Stock" },
                            { field: "balance_stock", headerName: "Balance Stock", highlight: true },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default StockOutList;
