import { PMDetailsTable } from "../../pmcomponents";
import { BaseWidget, BaseWidgetProps } from "../BaseWidget";
import { PMDetailsTableProps } from "../../pmcomponents/pmdetailstable/PMDetailsTable";

export interface DetailTableWidgetProps extends BaseWidgetProps {
}

export const DetailTableWidget = (props: DetailTableWidgetProps & PMDetailsTableProps) => {
    const { ...baseProps } = props

    return (
        <BaseWidget {...baseProps} bodyPadding={0}>
            <PMDetailsTable {...props} />
        </BaseWidget>
    )
}

DetailTableWidget.defaultProps = {
}

export default DetailTableWidget