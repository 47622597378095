import Moment from "moment";

const stockOutFormatter = (data, defaultFormData) => {
  const dataSet = data?.data || data;
  const formData = JSON.parse(JSON.stringify(defaultFormData));
  formData["stockout"]?.forEach((element) => {
    element["transaction_ref"] = dataSet["transaction_ref"];
    element["transaction_date"] = Moment(dataSet["transaction_date"]).format(
      "yyyy-MM-DDTh:m:sZ"
    );
    element["transaction_type"] = "Sale";
  });
  return formData;
};

export default stockOutFormatter;
