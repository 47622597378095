const customerPhoneListFormatter = (data, dataKey) => {
  const dataSet = {};
  dataSet[dataKey] = data?.items ? JSON.parse(JSON.stringify(data?.items)) : data ? [JSON.parse(JSON.stringify(data))] : [];
  dataSet[dataKey].forEach((element) => {
    element["label"] = element["phone_number"];
  });
  return dataSet;
};

export default customerPhoneListFormatter;
