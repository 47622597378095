
const orderStatsFormatter = (data, dataKey) => {
  const dataset = {}
  var count = 0
  var sum = 0
  var sum_string = ""
  if (data){
    count = data[0].count
    sum = (data[0].sum || 0.0).toFixed(2)
  }
  if (sum < 100000){
    sum_string = (sum/1000).toFixed(2)+"K"
  }else if(sum < 10000000){
    
    sum_string = (sum/100000).toFixed(2)+"L"
  }
  else{
    sum_string = (sum/10000000).toFixed(2)+"Cr"

  }
  dataset[dataKey] = {"count":count,"sum":sum_string}
  return dataset;
};
  
export default orderStatsFormatter;
